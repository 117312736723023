import { useSongStore } from '../song.store';

export function useSongSelector() {
  // State
  const songVersions = useSongStore(state => state.versions);
  const currentVersion = useSongStore(state => state.currentVersionId);
  const isOriginalStems = useSongStore(state => state.isOriginalStems);
  const sharedUsers = useSongStore(state => state.sharedUsers);
  const isUploading = useSongStore(state => state.isUploading);
  const isDeletingSong = useSongStore(state => state.isDeletingSong);
  const song = useSongStore(state => state.song);
  const description = useSongStore(state => state.description);
  const createdAt = useSongStore(state => state.createdAt);
  const updatedAt = useSongStore(state => state.updatedAt);
  const uploadedBy = useSongStore(state => state.uploadedBy);
  const bpm = useSongStore(state => state.bpm);
  const key = useSongStore(state => state.key);
  const songParentId = useSongStore(state => state.songParentId);
  const stems = useSongStore(state => state.stems);
  const isDownloadingFiles = useSongStore(state => state.isDownloadingFiles);
  const isNewSong = useSongStore(state => state.isNewSong);
  const metadata = useSongStore(state => state.metadata);
  const accessShareType = useSongStore(state => state.accessShareType);
  const accessTypePublic = useSongStore(state => state.accessTypePublic);
  const preparedSharedUsers = useSongStore(state => state.preparedSharedUsers);
  const isCompressedExists = useSongStore(state => state.isCompressedExists);
  const inputShareType = useSongStore(state => state.inputShareType);
  const fileMetadata = useSongStore(state => state.fileMetadata);
  const isCommentMode = useSongStore(state => state.isCommentMode);
  const comments = useSongStore(state => state.comments);
  const zoomLevel = useSongStore(state => state.zoomLevel);

  // Actions
  const reorderStems = useSongStore(state => state.reorderStems);
  const getSongVersions = useSongStore(state => state.getSongVersions);
  const getSongVersionsSidebar = useSongStore(state => state.getSongVersionsSidebar);
  const getSharedUsers = useSongStore(state => state.getSharedUsers);
  const getSongWithStems = useSongStore(state => state.getSongWithStems);
  const getSongWithStemsSidebar = useSongStore(state => state.getSongWithStemsSidebar);

  //
  const getSongStems = useSongStore(state => state.getSongStems);
  //
  const setIsOriginalStems = useSongStore(state => state.setIsOriginalStems);
  const changeVersion = useSongStore(state => state.changeVersion);
  const deleteVersion = useSongStore(state => state.deleteVersion);
  const clearState = useSongStore(state => state.clearState);
  const clearSong = useSongStore(state => state.clearSong);
  const clearStems = useSongStore(state => state.clearStems);
  const setCachedFiles = useSongStore(state => state.setCachedFiles);
  const downloadStem = useSongStore(state => state.downloadStem);
  const deleteSong = useSongStore(state => state.deleteSong);
  const deleteStem = useSongStore(state => state.deleteStem);
  const downloadFiles = useSongStore(state => state.downloadFiles);
  const inviteUser = useSongStore(state => state.inviteUser);
  const addVersion = useSongStore(state => state.addVersion);
  const addStems = useSongStore(state => state.addStems);
  const createNewSong = useSongStore(state => state.createNewSong);
  const setIsNewSong = useSongStore(state => state.setIsNewSong);
  const deleteSharedUser = useSongStore(state => state.deleteSharedUser);
  const renameSong = useSongStore(state => state.renameSong);
  const renameSongOnMainSongPage = useSongStore(state => state.renameSongOnMainSongPage);
  const renameVersionWithoutSongTrack = useSongStore(state => state.renameVersionWithoutSongTrack);
  const renameStem = useSongStore(state => state.renameStem);
  const editDescription = useSongStore(state => state.editDescription);
  const editBPM = useSongStore(state => state.editBPM);
  const editKey = useSongStore(state => state.editKey);
  const setSong = useSongStore(state => state.setSong);
  const addAlbumTitle = useSongStore(state => state.addAlbumTitle);
  const addGenre = useSongStore(state => state.addGenre);
  const addArtist = useSongStore(state => state.addArtist);
  const addComposer = useSongStore(state => state.addComposer);
  const addPublisher = useSongStore(state => state.addPublisher);
  const addISRCCode = useSongStore(state => state.addISRCCode);
  const addUPCCode = useSongStore(state => state.addUPCCode);
  const addEditLyrics = useSongStore(state => state.addEditLyrics);
  const deleteAlbumTitle = useSongStore(state => state.deleteAlbumTitle);
  const deleteGenre = useSongStore(state => state.deleteGenre);
  const deleteArtist = useSongStore(state => state.deleteArtist);
  const deleteComposer = useSongStore(state => state.deleteComposer);
  const deletePublisher = useSongStore(state => state.deletePublisher);
  const deleteISRCCode = useSongStore(state => state.deleteISRCCode);
  const deleteUPCCode = useSongStore(state => state.deleteUPCCode);
  const changeSampleRate = useSongStore(state => state.changeSampleRate);
  const changeBitDepth = useSongStore(state => state.changeBitDepth);
  const setAccessShareType = useSongStore(state => state.setAccessShareType);
  const setAccessTypePublic = useSongStore(state => state.setAccessTypePublic);
  const addPreparedSharedUser = useSongStore(state => state.addPreparedSharedUser);
  const deletePreparedSharedUser = useSongStore(state => state.deletePreparedSharedUser);
  const setInputShareType = useSongStore(state => state.setInputShareType);
  const changeMultiTrackMode = useSongStore(state => state.changeMultiTrackMode);
  const changeSoloMutedStem = useSongStore(state => state.changeSoloMutedStem);
  const setFileMetadata = useSongStore(state => state.setFileMetadata);
  const toggleCommentMode = useSongStore(state => state.toggleCommentMode);
  const getComments = useSongStore(state => state.getComments);
  const setZoomLevel = useSongStore(state => state.setZoomLevel);

  return {
    fileMetadata,
    songVersions,
    currentVersion,
    sharedUsers,
    song,
    isCompressedExists,
    stems,
    isOriginalStems,
    setIsOriginalStems,
    getSongVersions,
    getSharedUsers,
    getSongWithStems,
    changeVersion,
    deleteVersion,
    clearState,
    clearSong,
    setCachedFiles,
    downloadStem,
    isUploading,
    deleteSong,
    isDeletingSong,
    deleteStem,
    downloadFiles,
    isDownloadingFiles,
    inviteUser,
    addVersion,
    addStems,
    setIsNewSong,
    isNewSong,
    clearStems,
    createNewSong,
    deleteSharedUser,
    renameSong,
    renameStem,
    editDescription,
    editBPM,
    editKey,
    renameSongOnMainSongPage,
    reorderStems,
    description,
    createdAt,
    updatedAt,
    uploadedBy,
    bpm,
    key,
    songParentId,
    setSong,
    renameVersionWithoutSongTrack,
    addAlbumTitle,
    addGenre,
    addArtist,
    addComposer,
    addPublisher,
    addISRCCode,
    addUPCCode,
    addEditLyrics,
    deleteAlbumTitle,
    deleteGenre,
    deleteArtist,
    deleteComposer,
    deletePublisher,
    deleteISRCCode,
    deleteUPCCode,
    metadata,
    changeSampleRate,
    changeBitDepth,
    accessShareType,
    accessTypePublic,
    preparedSharedUsers,
    setAccessShareType,
    setAccessTypePublic,
    addPreparedSharedUser,
    deletePreparedSharedUser,
    inputShareType,
    setInputShareType,
    changeMultiTrackMode,
    changeSoloMutedStem,
    getSongWithStemsSidebar,
    getSongVersionsSidebar,
    toggleCommentMode,
    isCommentMode,
    comments,
    getComments,
    zoomLevel,
    setZoomLevel
  };
}
