import { useAuth0 } from '@auth0/auth0-react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

import CodeIcon from '@mui/icons-material/Code';
import { enqueueSnackbar } from 'notistack';
import { ReactComponent as ArrowDown } from '../assets/icons/arrow_down.svg';
import { ReactComponent as Choice } from '../assets/icons/choice.svg';
import { ReactComponent as HighQualityIcon } from '../assets/icons/highQIcon.svg';
import { ReactComponent as ShowLufsIcon } from '../assets/icons/showLufsIcon.svg';
import { ReactComponent as StandartIcon } from '../assets/icons/standartIcon.svg';
import { ReactComponent as UploadIconMenu } from '../assets/icons/uploadIconMenu.svg';
import { ReactComponent as VersionPlayerMode } from '../assets/icons/versionMode.svg';

import {
  Backdrop,
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Slider,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { NotificationType } from '../@types/notifications';
import { stemToUpload } from '../@types/uploadFile';
// @ts-ignore
import { AudioFile, SoloMutedStems } from '../@types/songs';
import { ReactComponent as Wave } from '../assets/icons/wave.svg';
import { MetadataSidebar } from '../components/MetadataSidebar';
import { StemItem } from '../components/StemItem';
import { UploadArea } from '../components/UploadArea';
import { Playlist } from '../components/multitrack/Playlist';
import TimeScale from '../components/multitrack/Timescale';

import { FileType } from '../@types/files';
import { CombinedLufsItem } from '../components/CombinedLufsItem';
import { LufsItem } from '../components/LufsItem';
import '../components/multitrack/playlist.css';
import useSongAccess from '../hooks/useSongAccess';
import { SongDateUploading } from '../sections/mainSongPage/SongDateUploading';
import { SongDescription } from '../sections/mainSongPage/SongDescription';
import { SongDownload } from '../sections/mainSongPage/SongDownload';
import { SongLinkCopy } from '../sections/mainSongPage/SongLinkCopy';
import { SongMetadata } from '../sections/mainSongPage/SongMetadata';
import { SongName } from '../sections/mainSongPage/SongName';
import { SongPlayer } from '../sections/mainSongPage/SongPlayer';
import { SongSharing } from '../sections/mainSongPage/SongSharing';
import { SongShowButton } from '../sections/mainSongPage/SongShowButton';
import { SongVersions } from '../sections/mainSongPage/SongVersions';
import notificationService from '../services/notificationService';
import trackEvent from '../services/trackService';
import { useFoldersStore } from '../store/folders.store';
import { usePlaylistStore } from '../store/players.store';
import { useFoldersSelector } from '../store/selectors/folders.selector';
import { useSongSelector } from '../store/selectors/song.selector';
import { useUploadFilesSelector } from '../store/selectors/uploadFiles.selector';
import { useUserStore } from '../store/user.store';
import { copySharingLink } from '../utils/copyLink';
import { calculateFolderSize, FolderNode, getFile, readDirectory } from '../utils/fileUtils';
import { generateId } from '../utils/generateId';
import { processUnZipFile } from '../utils/useUnzipWorker';
import './resizeHandle.css';
import './scroll.css';
import CommentDialog from '../sections/comments/CommentDialog';
import { CommentsHistoryButton } from '../sections/comments/CommentsHistoryButton';
import { CommentHistorySidebar } from '../sections/comments/CommentHistorySidebar';
import { ReactComponent as CommentIcon } from '../assets/icons/commentIcon.svg';

export function MainSongPage() {
  const { folderId, songId } = useParams();
  const { canEdit, isOwner, ownerEmail, userId, canView, canDownload } = useSongAccess(songId);
  const { renameSongInSidebar } = useFoldersSelector();
  const { uploadingSongs } = useUploadFilesSelector();
  const { user } = useAuth0();
  const { addUploadStems, lastQueue } = useUploadFilesSelector();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const isHorizontalMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileLandscape = useMediaQuery('(max-height: 500px) and (orientation: landscape)');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [isBackdrop, setIsBackdrop] = useState(false);
  const [commentInfo, setCommentInfo] = useState<undefined | { stemId: string; duration: number }>(undefined);
  const [isHistoryCommentsOpened, setIsHistoryCommentsOpened] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    accessShareType,
    songVersions,
    currentVersion,
    song,
    songParentId,
    stems,
    getSongVersions,
    getSongWithStems,
    clearSong,
    clearStems,
    reorderStems,
    createdAt,
    uploadedBy,
    bpm,
    setSong,
    addStems,
    isOriginalStems,
    setIsOriginalStems,
    isNewSong,
    isCompressedExists,
    setIsNewSong,
    changeMultiTrackMode,
    toggleCommentMode,
    getComments,
    comments,
    isCommentMode,
    zoomLevel,
    setZoomLevel
  } = useSongSelector();
  const { setUploadData } = useUploadFilesSelector();
  const hasMounted = useRef(false);
  const playlistState = usePlaylistStore(state => state.playListStates.find(playlistState => playlistState.versionId === currentVersion));
  const playlistStates = usePlaylistStore(state => state.playListStates);
  let playlist: any;
  let waveSurfer: any;
  let isSongRendered;
  let stemsCountForLoading: number = 0;
  let loadedStemsCount;
  let isStemsRendered: boolean = false;
  let playlistEventEmitter: any;
  let isCompressed: boolean = false;
  if (playlistState) {
    playlist = playlistState.playlist;
    waveSurfer = playlistState.waveSurfer;
    isSongRendered = playlistState.isSongRendered;
    stemsCountForLoading = playlistState.stemsCountForLoading;
    loadedStemsCount = playlistState.loadedStemsCount;
    isStemsRendered = playlistState.isStemsRendered;
    playlistEventEmitter = playlistState.eventEmitter;
  }

  const handleIsSongRendered = usePlaylistStore(state => state.handleIsSongRendered);
  const handleIsStemsRendered = usePlaylistStore(state => state.handleIsStemsRendered);
  const setStemsCountForLoading = usePlaylistStore(state => state.setStemsCountForLoading);
  const incrLoadedStemsCount = usePlaylistStore(state => state.incrLoadedStemsCount);
  const clearLoadedStemsCount = usePlaylistStore(state => state.clearLoadedStemsCount);
  const clearStemPlayers = usePlaylistStore(state => state.clearStemPlayers);
  const init = usePlaylistStore(state => state.init);
  const [soloMutedStems, setSoloMutedStems] = useState<Map<string, SoloMutedStems>>(new Map());
  const [isDraggingStems, setIsDraggingStems] = useState(false);

  const [isSongPlaying, setIsSongPlaying] = useState(false);
  const [isStemsPlaying, setIsStemsPlaying] = useState(false);

  const multitrackContainerRef = useRef<HTMLDivElement | null>(null);

  const [isReorderingStems, setIsReorderingStems] = useState(false);
  const [isSidebarOpened, setIsSidebarOpened] = useState(false);
  const [isSongShown, setIsSongShown] = useState(false);
  const [height, setHeight] = useState('0px');
  const [showLufs, setShowLufs] = useState(false);
  const [versionMode, setVersionMode] = useState<boolean | undefined>(undefined);
  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null);
  const [playlistPanelWidth, setPlaylistPanelWidth] = useState<number>(0);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isEmbed = params.get('isEmbed') === 'true';

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuEl(event.currentTarget);
  };

  const closeMenu = () => {
    setMenuEl(null);
  };

  useEffect(() => {
    if (currentVersion && (canEdit || isOwner)) {
      const intervalId = setInterval(() => {
        getComments();
      }, 5000);

      return () => clearInterval(intervalId);
    }
  }, [currentVersion, canEdit, isOwner]);

  const [isStemsLoaded, setIsStemsLoaded] = useState(false);

  const [handleRenameSong, setHandleRenameSong] = useState<(() => void) | null>(null);
  const maxSizeStorage = useUserStore(state => state.maxSizeStorage);
  const usedStorage = useUserStore(state => state.usedStorage);
  const userPlan = useUserStore(state => state.userPlan);
  const { updateSong } = useFoldersStore();

  const emitAddSong = (stems: any): void => {
    playlist?.load(stems, () => incrLoadedStemsCount(currentVersion!));
  };
  const playlistEventEmitterRef = useRef(playlistEventEmitter);

  useEffect(() => {
    playlistEventEmitterRef.current = playlistEventEmitter;
  }, [playlistEventEmitter]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const activeElement = document.activeElement;

      if (activeElement?.tagName === 'INPUT' || activeElement?.tagName === 'TEXTAREA') {
        return;
      }

      if (event.key === ' ') {
        event.preventDefault();
        handleButtonClick();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (hasMounted.current) {
      setIsStemsLoaded(false);
      clearStemPlayers();
      clearStems();
      setIsSongPlaying(false);
      setIsStemsPlaying(false);
      getSongWithStems().then(files => {
        setStemsCountForLoading(files.stems.length, currentVersion!);

        if (!files.stems.length) {
          handleIsStemsRendered(true, currentVersion!);
        }
      });
    } else {
      hasMounted.current = true;
    }
  }, [isOriginalStems]);

  useEffect(() => {
    if (multitrackContainerRef.current) {
      const newHeight = `calc(100svh - 24px - ${multitrackContainerRef.current.offsetTop}px)`;
      setHeight(newHeight);
    }
  }, [isSongShown, isMobileLandscape]);

  useEffect(() => {
    if (playlist) {
      playlist.renderTimeScale = function () {
        const controlWidth = this.controls.show ? this.controls.width : 0;
        const timeScale = new TimeScale(
          this.duration,
          this.scrollLeft,
          this.samplesPerPixel,
          this.sampleRate,
          this.colors,
          controlWidth,
          bpm || 0
        );
        return timeScale.render();
      };
      playlist.drawRequest();
    }
  }, [playlist]);

  useEffect(() => {
    if (playlist) {
      const zoomIndex = playlist.zoomLevels.length - zoomLevel - 1;
      if (playlist.zoomIndex !== zoomIndex) {
        playlist.setZoomIndex(zoomIndex);
        playlist.setZoom(playlist.zoomLevels[zoomIndex]);
        playlist.drawRequest();
      }
    }
  }, [zoomLevel, playlist]);

  useEffect(() => {
    setIsStemsLoaded(false);
    playlistStates.forEach(playlistState => playlistState?.eventEmitter.emit('pause'));

    if (isNewSong) {
      setSoloMutedStems(new Map());
      setIsSongPlaying(false);
      setIsStemsPlaying(false);
      init(currentVersion!);
      setStemsCountForLoading(stems.length, currentVersion!);
      setIsNewSong(false);
      rerenderWithCache(stems);
      setIsStemsLoaded(true);
    } else {
      user && trackEvent('song_view', { songId: songId!, songName: song?.name ?? '', folderId: folderId ?? '' });
      clearSong();
      clearStems();
      setSoloMutedStems(new Map());
      setIsSongPlaying(false);
      setIsStemsPlaying(false);
      const uploadState = uploadingSongs.find(uploadSong => uploadSong.songId === songId);
      const getDataAndRender = async () => {
        const currentVersion = await getSongVersions(songId!);
        const playListState = init(currentVersion!);
        await getSongWithStems(uploadState?.stems, uploadState?.song).then(files => {
          if (!files.song) {
            handleIsSongRendered(true, currentVersion!);
          }

          setVersionMode(files.isVersionMode);
          if (files) {
            if (playListState?.soloMutedStems) {
              setSoloMutedStems(playListState?.soloMutedStems);
            } else {
              setSoloMutedStems(() =>
                files?.stems?.reduce((map, stem) => map.set(stem.id, { id: stem.id, solo: false, muted: false }), new Map())
              );
            }
          }
          setStemsCountForLoading(files.stems.length, currentVersion!);
          setIsStemsLoaded(true);

          if (!files.stems.length) {
            handleIsStemsRendered(true, currentVersion!);
          }
        });
      };

      getDataAndRender();
    }
    return () => {};
  }, [songId, folderId]);

  useEffect(() => {
    if (song) {
      setIsSongShown(true);
    }
  }, [song]);

  useEffect(() => {
    if (isStemsRendered && versionMode !== undefined) {
      if (!versionMode) {
        let i = -1;
        let currentIndex = 0;
        let foundKey: string | undefined;

        soloMutedStems.forEach((value, key) => {
          if (i === -1 && value.solo) {
            i = currentIndex;
            foundKey = key;
          }
          currentIndex++;
        });

        if (i !== -1) {
          handleButtonClick();

          playlistEventEmitter.emit('solo', { track: playlist.tracks[i], i });

          setSoloMutedStems(prevState => {
            const newMap = new Map(prevState);

            if (foundKey) {
              newMap.set(foundKey, { ...newMap.get(foundKey), solo: false } as SoloMutedStems);
            }

            return newMap;
          });
        }
      } else {
        if (isStemsPlaying) {
          handleButtonClick();
        }

        let currentIndex = 0;

        soloMutedStems.forEach((value, key) => {
          if (value.solo) {
            playlistEventEmitter.emit('solo', { track: playlist.tracks[currentIndex], currentIndex });

            setSoloMutedStems(prevState => {
              const newMap = new Map(prevState);

              newMap.set(key, { ...value, solo: false });

              return newMap;
            });
          }

          if (value.muted) {
            playlistEventEmitter.emit('mute', { track: playlist.tracks[currentIndex], currentIndex });

            setSoloMutedStems(prevState => {
              const newMap = new Map(prevState);

              newMap.set(key, { ...value, muted: false });

              return newMap;
            });
          }

          currentIndex++;
        });
      }

      (isOwner || canEdit) && changeMultiTrackMode(currentVersion!, versionMode);
    }
  }, [versionMode]);

  useEffect(() => {
    const handleOrientationChange = () => {
      if (isStemsPlaying) {
        playlistEventEmitterRef.current?.emit('pause');
        setTimeout(() => {
          playlistEventEmitterRef.current?.emit('play');
        }, 2000);
      }
    };

    window.addEventListener('orientationchange', handleOrientationChange);

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, [isSongPlaying, isStemsPlaying]);

  const rerenderWithCache = (cachedStems: AudioFile[]) => {
    setSoloMutedStems(() => cachedStems?.reduce((map, stem) => map.set(stem.id, { id: stem.id, solo: false, muted: false }), new Map()));
  };
  const handleZoomSliderChange = (event: Event, value: number | number[], activeThumb: number) => {
    setZoomLevel(value as number);
  };

  const handleStemsReorder = async (result: DropResult) => {
    await reorderStems(result);
    if (playlist) {
      const sourceStem = playlist.tracks[result.source.index];
      playlist.tracks.splice(result.source.index, 1);
      playlist.tracks.splice(result.destination?.index ?? 0, 0, sourceStem);
      playlist.drawRequest();
    }
    setIsReorderingStems(false);
  };

  const playListStopPlayingHandler = () => {
    setIsStemsPlaying(false);
  };

  const uploadSong = async (file: File) => {
    setSong({
      id:
        'song_' +
        generateId() +
        '_' +
        new Date()
          .toISOString()
          .replace(/[-T:.]/g, '_')
          .slice(0, -1),
      mime: file.type,
      name: file.name,
      size: file.size,
      order: 0,
      url: URL.createObjectURL(file)
    });

    if (currentVersion === songVersions.sort((a, b) => a.versionNumber - b.versionNumber).at(songVersions.length - 1)?.id) {
      renameSongInSidebar(folderId!, songId!, file.name, user?.name || user?.email);
    }

    setUploadData(
      {
        file,
        progress: 0,
        estimatedTime: 0,
        versionId: currentVersion!
      },
      [],
      songId ?? '',
      folderId!,
      FileType.Audio
    );
  };

  const checkEntries = async (entries: FileSystemEntry[]): Promise<boolean> => {
    for (const entry of entries) {
      if (entry.isDirectory) {
        return false;
      }

      const file = await getFile(entry as FileSystemFileEntry);
      const mimeType = file.type || 'application/octet-stream';

      if (!mimeType.startsWith('audio/')) {
        return false;
      }
    }

    return true;
  };

  function sortFolderNode(folderNode: FolderNode): FolderNode {
    folderNode.files.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));

    folderNode.subFolders.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));

    folderNode.subFolders.forEach(sortFolderNode);

    return folderNode;
  }

  const uploadStems = async (fileList?: FileList, fileSystemEntry?: FileSystemEntry[]) => {
    trackEvent('batch-upload', { type: 'add-stems' });
    let newStems: stemToUpload[] = [];
    const folderTree: FolderNode[] = [];
    setIsBackdrop(true);

    if (fileSystemEntry?.length) {
      const justAudioFiles = await checkEntries(fileSystemEntry);

      const filePromises = fileSystemEntry.map(async entry => {
        if (entry.isDirectory) {
          const folderNode = await readDirectory(entry as FileSystemDirectoryEntry);

          folderTree.push(folderNode);
        } else {
          const file = await getFile(entry as FileSystemFileEntry);
          const mimeType = file.type || 'application/octet-stream';

          if (mimeType === 'application/zip') {
            const stems = await processUnZipFile(file);

            folderTree.push(...stems);
          } else if (justAudioFiles) {
            if (!folderTree.length) {
              folderTree.push({ id: 'no_folder', name: 'no folder', files: [], subFolders: [] });
            }

            folderTree?.at(0)?.files.push(file);
          }
        }
      });

      await Promise.all(filePromises);
    } else if (fileList?.length) {
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];

        if (file.type === 'application/zip') {
          const stems = await processUnZipFile(file);

          folderTree.push(...stems);
        } else {
          if (!folderTree.length) {
            folderTree.push({ id: 'no_folder', name: 'no folder', files: [], subFolders: [] });
          }

          folderTree?.at(0)?.files.push(file);
        }
      }
    }

    setIsBackdrop(false);
    closeMenu();

    const size = calculateFolderSize(folderTree);

    if (!(size + usedStorage > maxSizeStorage)) {
      const sortedFolderTree = folderTree.map(folder => sortFolderNode(folder));

      newStems =
        sortedFolderTree?.at(0)?.files.map((file, i) => ({
          file,
          progress: 0,
          estimatedTime: 0,
          stemId:
            'stem_' +
            generateId() +
            '_' +
            new Date()
              .toISOString()
              .replace(/[-T:.]/g, '_')
              .slice(0, -1),
          queueId: lastQueue + 1,
          versionId: currentVersion!,
          order: stems.length + i + 1
        })) ?? [];

      addUploadStems(newStems, songId!, folderId ?? '');

      const cachedStems: AudioFile[] = newStems.map((stem, i) => ({
        id: stem.stemId,
        mime: stem.file.type,
        name: stem.file.name,
        createdAt: new Date().toString(),
        uploadedBy: user?.name,
        size: stem.file.size,
        order: i + 1,
        url: URL.createObjectURL(stem.file)
      }));

      addStems(cachedStems);
      if (folderId && songId) {
        updateSong(folderId, songId, user?.name || user?.email);
      }
      if (isStemsRendered) {
        clearLoadedStemsCount(currentVersion!);
        setStemsCountForLoading(cachedStems.length, currentVersion!);
      } else {
        setStemsCountForLoading(cachedStems.length + stemsCountForLoading, currentVersion!);
      }

      if (emitAddSong)
        emitAddSong(
          cachedStems.map(stem => {
            return { src: stem.url, name: stem.name };
          })
        );
    } else {
      enqueueSnackbar("You haven't enough space. Update your plan.", { variant: 'error' });

      navigate('/plans');
    }
  };

  const handleButtonClick = () => {
    setIsSongPlaying(false);
    waveSurfer?.pause();

    setIsStemsPlaying(isPlaying => {
      if (isPlaying) {
        playlistEventEmitterRef.current?.emit('pause');
      } else {
        playlistEventEmitterRef.current?.emit('play');
        if (!isOwner && !!user) {
          !!user && notificationService.createNotification(NotificationType.ListenSong, songId!);
        }
      }
      return !isPlaying;
    });
  };

  const handleCloseLufs = () => {
    setShowLufs(false);
  };

  const createCommentDialog = (stemIndex: number, duration: number) => {
    let stemId = stems?.at(stemIndex)?.id;

    if (stemId) {
      setCommentInfo({ stemId, duration });
      toggleCommentMode(false);
    }
  };

  return !isMobile ? (
    <Grid container height={isEmbed ? '100vh' : 1} direction={'row'} columnSpacing={1}>
      <Backdrop sx={theme => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })} open={isBackdrop}>
        <CircularProgress sx={{ color: '#008EF3' }} />
      </Backdrop>
      <Grid item xs={isSidebarOpened ? 8.8 : isHistoryCommentsOpened ? 8.2 : 12}>
        <Stack direction="column" height={1} sx={{ borderRadius: isEmbed ? 'unset' : '8px' }}>
          <Stack
            direction="column"
            rowGap={1.5}
            p={3}
            pb={0}
            sx={{
              borderTopLeftRadius: isEmbed ? 'unset' : 8,
              borderTopRightRadius: isEmbed ? 'unset' : 8,
              background: 'linear-gradient(180deg, rgba(0, 142, 243, 0.3) 0%, rgba(0, 142, 243, 0.54) 0.02%, rgba(18, 18, 18, 0) 110%)'
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <SongName canEdit={canEdit || isOwner} setHandleRename={setHandleRenameSong} />
              {!isEmbed && (
                <Stack direction="row" alignItems="flex-start" sx={{ gap: '11px' }}>
                  {canEdit || isOwner ? <SongSharing isOwner={isOwner} ownerEmail={ownerEmail} disabled={false} /> : null}
                  <SongLinkCopy disabled={false} />
                  {canDownload && canEdit ? <SongDownload disabled={false} /> : null}
                </Stack>
              )}
            </Stack>
            {!isEmbed && (
              <Stack direction="row" gap={'8px'}>
                <SongVersions
                  canEdit={isOwner || canEdit}
                  turnOffSong={() => setIsSongPlaying(false)}
                  turnOffPlaylist={() => setIsStemsPlaying(false)}
                  setSoloMutedStems={setSoloMutedStems}
                />

                <SongMetadata isSidebarOpened={isSidebarOpened} toggleSidebar={() => setIsSidebarOpened(prevState => !prevState)} />
                <CommentsHistoryButton
                  isHistoryCommentsOpened={isHistoryCommentsOpened}
                  toggleHistoryComments={() => setIsHistoryCommentsOpened(prevState => !prevState)}
                />
                {song && <SongShowButton isSongShown={isSongShown} toggleSongShown={() => setIsSongShown(prev => !prev)} song={song} />}
              </Stack>
            )}
            <Grid container alignItems={'center'}>
              <Grid item xs={5} paddingBottom={2}>
                {!isEmbed && (
                  <Stack direction="column">
                    <SongDescription canEdit={canEdit || isOwner} />
                    {!!createdAt && !!uploadedBy && <SongDateUploading uploadedBy={uploadedBy} createdAt={createdAt} />}
                  </Stack>
                )}
              </Grid>
              <Grid item xs={7} pl={1}>
                {isSongShown && song?.url && (
                  <SongPlayer
                    isOwner={isOwner}
                    waveSurfer={waveSurfer}
                    playlistEventEmitter={playlistEventEmitter}
                    setIsSongPlaying={setIsSongPlaying}
                    setIsStemsPlaying={setIsStemsPlaying}
                    isStemsPlaying={isStemsPlaying}
                    isSongPlaying={isSongPlaying}
                  />
                )}
                <Grid container justifyContent="flex-end" alignItems={'flex-end'}>
                  <Grid item>
                    <IconButton size="small" onClick={() => setZoomLevel(zoomLevel - 1)} disabled={zoomLevel <= 0}>
                      <ZoomOutIcon />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Slider
                      min={0}
                      max={12}
                      size="small"
                      aria-label="Zoom"
                      value={zoomLevel}
                      onChange={handleZoomSliderChange}
                      sx={{
                        width: 70,
                        '& .MuiSlider-thumb': {
                          backgroundColor: 'white'
                        },
                        '& .MuiSlider-rail': {
                          backgroundColor: '#131313'
                        }
                      }}
                    />
                  </Grid>

                  <Grid item>
                    <IconButton size="small" onClick={() => setZoomLevel(zoomLevel + 1)} disabled={zoomLevel >= 12}>
                      <ZoomInIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Stack>
          <Box
            display={'flex'}
            position={'relative'}
            flexGrow={1}
            height={1}
            ref={multitrackContainerRef}
            sx={{ backgroundColor: '#151515', borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}
          >
            {(isDraggingStems || (isStemsLoaded && stemsCountForLoading === 0 && songParentId)) && (
              <Box
                sx={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  zIndex: 10
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    background: 'url(/assets/backgroundMultitrack.png) no-repeat',
                    objectFit: 'cover',
                    backgroundSize: 'cover'
                  }}
                >
                  <Box
                    sx={{
                      fontFamily: 'DM Sans, sans-serif',
                      display: 'flex',
                      cursor: 'copy',
                      background: 'linear-gradient(266deg, #008EF3 8.39%, rgba(22, 227, 245, 0.85) 88.47%)',
                      width: '100%',
                      height: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      userSelect: 'none',
                      color: 'white',
                      fontSize: '28px',
                      borderRadius: '0px 0px 8px 8px'
                    }}
                  >
                    <Box
                      component="label"
                      onDrop={async (e: React.DragEvent<HTMLDivElement>) => {
                        if (!isDraggingStems) return;
                        e.preventDefault();

                        const items = e.dataTransfer.items;
                        const fileSystemEntries: FileSystemEntry[] = [];

                        Array.from(items).forEach(file => {
                          const entry = file.webkitGetAsEntry();

                          if (entry) {
                            fileSystemEntries.push(entry);
                          }
                        });

                        setIsDraggingStems(false);

                        uploadStems(undefined, fileSystemEntries);
                      }}
                      onDragEnter={() => {
                        if (!canEdit && !isOwner) return;

                        if (!isReorderingStems) setIsDraggingStems(true);
                      }}
                      onDragLeave={() => {
                        setIsDraggingStems(false);
                      }}
                      onDragEnd={() => {
                        setIsDraggingStems(false);
                      }}
                      onDragOver={e => {
                        e.preventDefault();
                      }}
                      sx={{ position: 'absolute', width: '100%', height: '100%', cursor: 'copy', zIndex: 20 }}
                    >
                      <UploadArea onUpload={uploadStems} multiple={true} disabled={!canEdit && !isOwner} />
                    </Box>
                    <Stack sx={{ textAlign: 'center' }} direction="column">
                      <Typography sx={{ display: 'flex', alignItems: 'center' }} variant="h4">
                        Drag and drop or upload {<Wave />} Stems here
                      </Typography>
                      <Typography variant="h5">(i.e. individual tracks, buses, groups, etc.)</Typography>
                    </Stack>
                  </Box>
                </Box>
              </Box>
            )}
            <SimpleBar
              onDragEnter={() => {
                if (!canEdit && !isOwner) return;

                setIsDraggingStems(true);
              }}
              style={{
                maxHeight: height,
                height: `calc(100svh - ${isHorizontalMobile ? 0 : 24}px - ${multitrackContainerRef.current?.offsetTop}px)`,
                width: '100%',
                borderTop: '1px solid #494949',
                overflowY: 'scroll',
                overflowX: 'hidden'
              }}
            >
              <Box>
                <Grid container direction="row" flexGrow={1} height={1}>
                  <PanelGroup direction="horizontal" autoSaveId="my-panel-layout" style={{ overflow: 'clipped' }}>
                    <Panel defaultSize={20} minSize={5} style={{ overflow: 'clipped', minWidth: '5%' }}>
                      <Grid item>
                        {/*Move buttons to another component*/}
                        <Stack
                          direction="row"
                          pl={isTablet ? 1.25 : 3}
                          pr={isTablet ? 1.25 : 2}
                          justifyContent="space-between"
                          sx={{
                            position: 'sticky',
                            top: '0',
                            background: '#151515',
                            borderRight: '1px solid #494949',
                            borderBottom: '1px solid #494949',
                            zIndex: '999',
                            paddingTop: versionMode ? '17px' : '15px',
                            paddingBottom: versionMode ? '16px' : '14px',
                            overflow: 'hidden',
                            gap: '32px'
                          }}
                        >
                          {' '}
                          <Stack
                            direction="row"
                            justifyContent={'start'}
                            sx={{
                              backgroundColor: '#343434',
                              maxHeight: '32px',
                              width: !versionMode ? '60px' : 'unset',
                              padding: !versionMode ? '2px' : '0px',
                              borderRadius: '100px'
                            }}
                          >
                            {!versionMode && (
                              <Tooltip title={'Play stems'}>
                                <Button
                                  size="small"
                                  sx={{
                                    minWidth: 'unset',
                                    width: '28px',
                                    height: '28px',
                                    borderRadius: '50%'
                                  }}
                                  disabled={!stems?.length}
                                  variant="contained"
                                  color="info"
                                  onClick={handleButtonClick}
                                >
                                  {isStemsPlaying ? <PauseIcon fontSize="small" /> : <PlayArrowIcon fontSize="small" sx={{ margin: 0 }} />}
                                </Button>
                              </Tooltip>
                            )}
                            <Tooltip title={'Playback quality'}>
                              <IconButton
                                size="small"
                                onClick={handleClick}
                                sx={{
                                  minWidth: 'unset',
                                  width: '28px',
                                  height: '28px',
                                  transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                                  transition: 'transform 0.3s ease'
                                }}
                              >
                                <ArrowDown />
                              </IconButton>
                            </Tooltip>

                            <Menu
                              disableAutoFocusItem
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              MenuListProps={{
                                style: {
                                  maxWidth: '284px'
                                }
                              }}
                              slotProps={{
                                paper: {
                                  style: {
                                    marginTop: '2px',
                                    marginLeft: '-30px'
                                  }
                                }
                              }}
                            >
                              <MenuItem
                                disabled={!isCompressedExists}
                                onClick={() => {
                                  setIsOriginalStems(false);
                                  handleClose();
                                }}
                                sx={{
                                  position: 'relative',
                                  fontSize: '14px',
                                  paddingTop: '3px',
                                  paddingLeft: '13px',
                                  height: '37px',
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                <ListItemIcon style={{ minWidth: 'unset', marginRight: '10px' }}>
                                  <StandartIcon />
                                </ListItemIcon>
                                <Typography sx={{ fontSize: '14px' }}>Standard</Typography>

                                <Stack
                                  sx={{
                                    position: 'absolute',
                                    right: '8px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '20px',
                                    height: '20px'
                                  }}
                                >
                                  {!isOriginalStems && isCompressedExists && <Choice opacity={'50%'} />}
                                </Stack>
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  if (userPlan) {
                                    setIsOriginalStems(true);
                                    handleClose();
                                  }
                                }}
                                sx={{
                                  position: 'relative',
                                  fontSize: '14px',
                                  paddingTop: '3px',
                                  paddingLeft: '13px',
                                  display: 'flex',
                                  height: '37px',
                                  alignItems: 'center',
                                  width: '294px',
                                  cursor: userPlan ? 'pointer' : 'auto'
                                }}
                              >
                                <ListItemIcon style={{ minWidth: 'unset', marginRight: '10px' }}>
                                  <HighQualityIcon opacity={userPlan ? '100%' : '50%'} />
                                </ListItemIcon>

                                <Typography
                                  sx={{
                                    fontSize: '14px',
                                    alignSelf: 'flex-start',
                                    marginRight: '53px',
                                    opacity: userPlan || !isCompressedExists ? '100%' : '50%'
                                  }}
                                >
                                  High quality
                                </Typography>
                                <Typography sx={{ fontSize: '11px', color: '#757575', position: 'absolute', top: '50%', left: '38px' }}>
                                  uncompressed
                                </Typography>

                                {!userPlan ? (
                                  <Tooltip title={'Upgrade to Premium or Creator to get access to this feature'}>
                                    <ButtonBase
                                      sx={{
                                        py: '4px',
                                        px: '12px',
                                        height: '25px',
                                        border: '1px solid #494949',
                                        borderRadius: 1,
                                        background: `linear-gradient(#2B2B2B 0 0) padding-box, linear-gradient(to right, #18C7D7, #0E8EF3) border-box`,
                                        borderImage: 'linear-gradient(#008EF3, #16E1F5)'
                                      }}
                                      onClick={event => {
                                        event.preventDefault();

                                        navigate('/plans');
                                      }}
                                    >
                                      <Typography
                                        color="transparent"
                                        sx={{
                                          fontSize: '14px',
                                          background: 'linear-gradient(266deg, #008EF3 8.39%, rgba(22, 227, 245, 0.9) 88.47%)',
                                          '-webkit-background-clip': 'text',
                                          backgroundClip: 'text'
                                        }}
                                      >
                                        Upgrade
                                      </Typography>
                                    </ButtonBase>
                                  </Tooltip>
                                ) : null}

                                <Stack
                                  sx={{
                                    position: 'absolute',
                                    right: '16px',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '20px',
                                    height: '20px'
                                  }}
                                >
                                  {(isOriginalStems || !isCompressedExists) && <Choice opacity={'50%'} />}
                                </Stack>
                              </MenuItem>
                            </Menu>
                          </Stack>
                          {stemsCountForLoading && !isStemsRendered && (
                            <Stack
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                              fontWeight={400}
                              fontSize={14}
                              color="text.primary"
                              fontFamily={'DM Sans, sans-serif'}
                            >
                              <CircularProgress size={14} sx={{ mr: 1 }} color="inherit" />
                              Loading {loadedStemsCount}/{stemsCountForLoading}
                            </Stack>
                          )}
                          <Stack direction="row" gap={0.5}>
                            <Menu
                              id="playlisyOptionsMenu"
                              anchorEl={menuEl}
                              open={Boolean(menuEl)}
                              onClose={closeMenu}
                              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                              }}
                              slotProps={{
                                paper: {
                                  sx: {
                                    mt: 1.5,
                                    background: 'rgba(0, 0, 0, 1)',
                                    width: '306px',
                                    fontSize: '14px',
                                    lineHeight: '24px',
                                    marginTop: '8px',
                                    marginLeft: '20px'
                                  }
                                }
                              }}
                              MenuListProps={{
                                'aria-labelledby': `playlistOptionsMenu`
                              }}
                            >
                              {isOwner || canEdit ? (
                                <MenuItem
                                  sx={{
                                    '&:not(:last-child)': {
                                      borderBottom: '1px solid rgba(245, 245, 245, 0.1)'
                                    }
                                  }}
                                  component="label"
                                >
                                  <UploadArea onUpload={uploadStems} multiple={true} />
                                  <ListItemIcon style={{ minWidth: 'unset', marginRight: '10px' }}>
                                    <UploadIconMenu />
                                  </ListItemIcon>
                                  <Typography>Upload more stems</Typography>
                                </MenuItem>
                              ) : null}
                              <MenuItem
                                sx={{
                                  '&:not(:last-child)': {
                                    borderBottom: '1px solid rgba(245, 245, 245, 0.1)'
                                  }
                                }}
                                onClick={() => {
                                  closeMenu();
                                  setShowLufs(isShown => !isShown);
                                }}
                              >
                                <ListItemIcon style={{ minWidth: 'unset', marginRight: '10px' }}>
                                  <ShowLufsIcon />
                                </ListItemIcon>
                                <Typography>{showLufs ? 'Hide' : 'Show'} LUFS Meter</Typography>
                              </MenuItem>
                              <MenuItem
                                sx={{
                                  '&:not(:last-child)': {
                                    borderBottom: '1px solid rgba(245, 245, 245, 0.1)'
                                  }
                                }}
                                onClick={() => {
                                  closeMenu();
                                  setVersionMode(isMode => !isMode);
                                }}
                              >
                                <ListItemIcon style={{ minWidth: 'unset', marginRight: '10px' }}>
                                  <VersionPlayerMode />
                                </ListItemIcon>
                                <Typography>{versionMode ? 'Turn off' : 'Switch to'} Version Player mode</Typography>
                              </MenuItem>
                              <MenuItem
                                sx={{
                                  '&:not(:last-child)': {
                                    borderBottom: '1px solid rgba(245, 245, 245, 0.1)'
                                  }
                                }}
                                onClick={() => {
                                  copySharingLink(accessShareType, folderId!, songId!, true);
                                  closeMenu();
                                }}
                              >
                                <ListItemIcon style={{ minWidth: 'unset', marginRight: '10px' }}>
                                  <CodeIcon />
                                </ListItemIcon>
                                <Typography> Copy Embed Link</Typography>
                              </MenuItem>
                            </Menu>
                            {!isEmbed && (
                              <Button
                                size="small"
                                variant="contained"
                                color="secondary"
                                disableElevation={true}
                                onClick={() => toggleCommentMode(!isCommentMode)}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  minWidth: 'unset',
                                  width: isMobile ? '40px' : '28px',
                                  height: isMobile ? '40px' : '28px',
                                  backgroundColor: !!isCommentMode ? '#008EF3' : 'rgb(75, 75, 75, 0.5)',
                                  padding: '0 8px',
                                  boxShadow: 'none'
                                }}
                              >
                                <CommentIcon />
                              </Button>
                            )}
                            {!!commentInfo && (
                              <CommentDialog
                                currentTime={commentInfo.duration}
                                stemId={commentInfo.stemId}
                                comment={undefined}
                                onClose={() => setCommentInfo(undefined)}
                                open={!!commentInfo}
                              />
                            )}
                            {(isOwner || canEdit) && !isEmbed ? (
                              <Button
                                size="small"
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  minWidth: 'unset',
                                  width: '28px',
                                  height: '28px'
                                }}
                                variant="contained"
                                color="secondary"
                                component="label"
                                id="playlistOptionsButton"
                                aria-controls={Boolean(menuEl) ? 'playlistOptionsMenu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={Boolean(menuEl) ? 'true' : undefined}
                                onClick={e => {
                                  openMenu(e as any);
                                }}
                              >
                                <MoreVertIcon sx={{ width: '16px', height: '16px' }} />
                              </Button>
                            ) : null}
                            {canDownload && !canEdit && !isEmbed ? <SongDownload disabled={false} small={true} /> : null}
                          </Stack>
                        </Stack>
                        <DragDropContext onDragStart={() => setIsReorderingStems(true)} onDragEnd={handleStemsReorder}>
                          <Droppable droppableId="droppable" direction="vertical">
                            {({ innerRef, droppableProps, placeholder }) => (
                              <Box ref={innerRef} {...droppableProps}>
                                {songId === songParentId &&
                                  stems?.map((stem, i) => (
                                    <Draggable isDragDisabled={!currentVersion} draggableId={stem.id} index={i} key={stem.id}>
                                      {({ innerRef, draggableProps, dragHandleProps }) => (
                                        <StemItem
                                          isReordering={isReorderingStems}
                                          innerRef={innerRef}
                                          draggableProps={draggableProps}
                                          dragHandleProps={dragHandleProps}
                                          key={stem.id}
                                          stem={stem}
                                          isSolo={soloMutedStems?.get(stem.id)?.solo}
                                          isMuted={soloMutedStems?.get(stem.id)?.muted}
                                          canEdit={canEdit}
                                          isOwner={isOwner}
                                          canDownload={canDownload}
                                          playlistEventEmitter={playlistEventEmitter}
                                          playlist={playlist}
                                          setSoloMuted={setSoloMutedStems}
                                          soloMutedStems={soloMutedStems}
                                          index={i}
                                          setIsBackdrop={setIsBackdrop}
                                          isVersionPlayer={versionMode}
                                          play={handleButtonClick}
                                          isStemsPlaying={isStemsPlaying}
                                        />
                                      )}
                                    </Draggable>
                                  ))}
                                {placeholder}
                              </Box>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </Grid>
                    </Panel>
                    <PanelResizeHandle className="resizeMainSongPage" hitAreaMargins={{ coarse: 10, fine: 5 }} />

                    {showLufs && (
                      <Grid
                        item
                        xs={1}
                        position="relative"
                        sx={{
                          '&:hover .icon-hover': {
                            opacity: 1
                          }
                        }}
                      >
                        <CombinedLufsItem soloMutedStems={soloMutedStems} playlist={playlist} handleCloseLufs={handleCloseLufs} />
                        <Stack direction={'column'}>
                          {stems?.map((stem, index) => (
                            <LufsItem ac={playlist?.ac} key={stem.id} audioBuffer={playlist?.tracks[index]?.buffer} />
                          ))}
                        </Stack>
                      </Grid>
                    )}
                    <Panel defaultSize={80} minSize={5} style={{ overflow: 'clipped' }} onResize={e => setPlaylistPanelWidth(e)}>
                      <Grid
                        item
                        position="relative"
                        sx={{
                          overflowX: 'scroll',
                          width: `${((multitrackContainerRef.current?.clientWidth ?? 0) * playlistPanelWidth) / 100}px`
                        }}
                      >
                        {!isNewSong && currentVersion && !!stems.length && (
                          <Playlist
                            soloMutedStems={soloMutedStems}
                            versionId={currentVersion!}
                            stems={stems.map(stem => ({ name: stem.name, src: stem.url, id: stem.id }))}
                            onStopPlaying={playListStopPlayingHandler}
                            createCommentDialog={createCommentDialog}
                          />
                        )}
                      </Grid>
                    </Panel>
                  </PanelGroup>
                </Grid>
              </Box>
            </SimpleBar>
          </Box>
        </Stack>
      </Grid>
      {isHistoryCommentsOpened && (
        <Grid item xs={3.8} height={1}>
          <CommentHistorySidebar closeSidebar={() => setIsHistoryCommentsOpened(false)} />
        </Grid>
      )}
      {isSidebarOpened && (
        <Grid item xs={3.2} height={1}>
          <MetadataSidebar
            playlist={playlist}
            isSidebarOpened={isSidebarOpened}
            closeSidebar={() => setIsSidebarOpened(false)}
            canEditMetadata={isOwner || canEdit}
          />
        </Grid>
      )}
    </Grid>
  ) : (
    <SimpleBar
      onDragEnter={() => {
        if (!canEdit && !isOwner) return;

        setIsDraggingStems(true);
      }}
      style={{
        maxHeight: `calc(100dvh - 56px - 8px)`,
        height: '100%',
        width: '100%',
        borderTop: '1px solid #494949',
        overflowY: 'scroll',
        overflowX: 'hidden'
      }}
    >
      <Grid container height={1} direction={'row'} columnSpacing={2}>
        <Grid item xs={12}>
          <Stack direction="column" height={1} sx={{ borderRadius: '8px' }}>
            <Stack
              direction="column"
              rowGap={2}
              pt={'21px'}
              pb={1}
              sx={{
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                background: 'linear-gradient(180deg, rgba(0, 142, 243, 0.3) 0%, rgba(0, 142, 243, 0.54) 0.02%, rgba(18, 18, 18, 0) 110%)'
              }}
            >
              <Stack direction="row">
                {stemsCountForLoading && !isStemsRendered ? (
                  <Stack
                    paddingLeft="16px"
                    direction="row"
                    fontFamily={'DM Sans, sans-serif'}
                    alignItems="center"
                    fontWeight={400}
                    fontSize={14}
                    color="text.primary"
                  >
                    <CircularProgress size={14} sx={{ mr: 1 }} color="inherit" />
                    Loading {loadedStemsCount}/{stemsCountForLoading}
                  </Stack>
                ) : (
                  ''
                )}
                {!isEmbed && (
                  <Stack flexGrow={1} direction="row" justifyContent="flex-end" px={2} pb={'4px'}>
                    <Stack direction="row" alignItems="flex-start" columnGap={'11px'}>
                      {canEdit || isOwner ? <SongSharing isOwner={isOwner} ownerEmail={ownerEmail} disabled={false} /> : null}
                      <SongLinkCopy disabled={false} />
                      {canDownload && canEdit ? <SongDownload disabled={false} /> : null}
                    </Stack>
                  </Stack>
                )}
              </Stack>
              <Divider />
              <Stack direction="column" rowGap={1} px={2}>
                <SongName canEdit={canEdit || isOwner} setHandleRename={setHandleRenameSong} />
                {!!createdAt && !!uploadedBy && !isEmbed && <SongDateUploading uploadedBy={uploadedBy} createdAt={createdAt} />}
                {!isEmbed && <SongDescription canEdit={canEdit || isOwner} />}
              </Stack>

              {!isEmbed && (
                <Stack direction="row" px={2} pt={'4px'} gap={'11px'}>
                  <SongVersions
                    canEdit={isOwner || canEdit}
                    turnOffSong={() => setIsSongPlaying(false)}
                    turnOffPlaylist={() => setIsStemsPlaying(false)}
                    setSoloMutedStems={setSoloMutedStems}
                  />
                  <SongMetadata isSidebarOpened={isSidebarOpened} toggleSidebar={() => setIsSidebarOpened(prevState => !prevState)} />
                  {song && <SongShowButton isSongShown={isSongShown} toggleSongShown={() => setIsSongShown(prev => !prev)} song={song} />}
                </Stack>
              )}
              {isSongShown && song?.url && (
                <Box px={2} mt={1}>
                  <SongPlayer
                    isOwner={isOwner}
                    waveSurfer={waveSurfer}
                    playlistEventEmitter={playlistEventEmitter}
                    setIsSongPlaying={setIsSongPlaying}
                    setIsStemsPlaying={setIsStemsPlaying}
                    isStemsPlaying={isStemsPlaying}
                    isSongPlaying={isSongPlaying}
                  />
                </Box>
              )}
              <Grid container justifyContent="flex-end" alignItems={'flex-end'}>
                <Grid item>
                  <IconButton size="small" onClick={() => setZoomLevel(zoomLevel - 1)} disabled={zoomLevel <= 0}>
                    <ZoomOutIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Slider
                    min={0}
                    max={12}
                    size="small"
                    aria-label="Zoom"
                    value={zoomLevel}
                    onChange={handleZoomSliderChange}
                    sx={{
                      width: 70,
                      '& .MuiSlider-thumb': {
                        backgroundColor: 'white'
                      },
                      '& .MuiSlider-rail': {
                        backgroundColor: '#131313'
                      }
                    }}
                  />
                </Grid>

                <Grid item>
                  <IconButton size="small" onClick={() => setZoomLevel(zoomLevel + 1)} disabled={zoomLevel >= 12}>
                    <ZoomInIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Stack>

            <Box
              display={'flex'}
              position={'relative'}
              flexGrow={1}
              height={1}
              ref={multitrackContainerRef}
              sx={{ backgroundColor: '#151515' }}
            >
              <Grid container direction="row" flexGrow={1} height={1}>
                <Grid item xs={5}>
                  {/*Move buttons to another component*/}
                  <Stack
                    direction="row"
                    pl={1.25}
                    pr={1.25}
                    justifyContent={versionMode ? 'flex-end' : 'space-between'}
                    sx={{
                      position: 'sticky',
                      top: '0',
                      background: 'black',
                      borderRight: '1px solid #494949',
                      borderBottom: '1px solid #494949',
                      zIndex: '999',
                      paddingTop: '17px',
                      paddingBottom: '16px',
                      minHeight: '74px'
                    }}
                  >
                    {!versionMode && (
                      <Button
                        size="small"
                        disabled={!stems?.length}
                        variant="contained"
                        color="info"
                        sx={{ height: '40px', width: '40px', minWidth: 'unset', boxShadow: 'none', borderRadius: '50px' }}
                        onClick={handleButtonClick}
                      >
                        {isStemsPlaying ? (
                          <PauseIcon fontSize="small" sx={{ margin: 0 }} />
                        ) : (
                          <PlayArrowIcon fontSize="small" sx={{ margin: 0 }} />
                        )}
                      </Button>
                    )}

                    <Menu
                      id="playlisyOptionsMenu"
                      anchorEl={menuEl}
                      open={Boolean(menuEl)}
                      onClose={closeMenu}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                      slotProps={{
                        paper: {
                          sx: {
                            mt: 1.5,
                            background: 'rgba(0, 0, 0, 1)',
                            width: '306px',
                            fontSize: '14px',
                            lineHeight: '24px',
                            marginTop: '8px',
                            marginLeft: '20px'
                          }
                        }
                      }}
                      MenuListProps={{
                        'aria-labelledby': `playlistOptionsMenu`
                      }}
                    >
                      {isOwner || canEdit ? (
                        <MenuItem
                          sx={{
                            '&:not(:last-child)': {
                              borderBottom: '1px solid rgba(245, 245, 245, 0.1)'
                            }
                          }}
                          component="label"
                        >
                          <UploadArea onUpload={uploadStems} multiple={true} />
                          <ListItemIcon style={{ minWidth: 'unset', marginRight: '10px' }}>
                            <UploadIconMenu />
                          </ListItemIcon>
                          <Typography>Upload more stems</Typography>
                        </MenuItem>
                      ) : null}
                      {/* <MenuItem
                              sx={{
                                '&:not(:last-child)': {
                                  borderBottom: '1px solid rgba(245, 245, 245, 0.1)'
                                }
                              }}
                              onClick={() => {
                                closeMenu();
                                setShowLufs(isShown => !isShown);
                              }}
                            >
                              <ListItemIcon style={{ minWidth: 'unset', marginRight: '10px' }}>
                                <ShowLufsIcon />
                              </ListItemIcon>
                              <Typography>{showLufs ? 'Hide' : 'Show'} LUFS Meter</Typography>
                            </MenuItem> */}
                      <MenuItem
                        sx={{
                          '&:not(:last-child)': {
                            borderBottom: '1px solid rgba(245, 245, 245, 0.1)'
                          }
                        }}
                        onClick={() => {
                          closeMenu();
                          setVersionMode(isMode => !isMode);
                        }}
                      >
                        <ListItemIcon style={{ minWidth: 'unset', marginRight: '10px' }}>
                          <VersionPlayerMode />
                        </ListItemIcon>
                        <Typography>{versionMode ? 'Turn off' : 'Switch to'} Version Player mode</Typography>
                      </MenuItem>
                      <MenuItem
                        sx={{
                          '&:not(:last-child)': {
                            borderBottom: '1px solid rgba(245, 245, 245, 0.1)'
                          }
                        }}
                        onClick={() => {
                          copySharingLink(accessShareType, folderId!, songId!, true);
                          closeMenu();
                        }}
                      >
                        <ListItemIcon style={{ minWidth: 'unset', marginRight: '10px' }}>
                          <CodeIcon />
                        </ListItemIcon>
                        <Typography> Copy Embed Link</Typography>
                      </MenuItem>
                    </Menu>
                    {(isOwner || canEdit) && !isEmbed ? (
                      <Button
                        size="small"
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          minWidth: 'unset',
                          width: '40px',
                          height: '40px'
                        }}
                        variant="contained"
                        color="secondary"
                        component="label"
                        id="playlistOptionsButton"
                        aria-controls={Boolean(menuEl) ? 'playlistOptionsMenu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={Boolean(menuEl) ? 'true' : undefined}
                        onClick={e => {
                          openMenu(e as any);
                        }}
                      >
                        <MoreVertIcon sx={{ width: '16px', height: '16px' }} />
                      </Button>
                    ) : null}
                    {canDownload && !canEdit && !isEmbed ? <SongDownload disabled={false} small={true} /> : null}
                  </Stack>
                  <DragDropContext onDragStart={() => setIsReorderingStems(true)} onDragEnd={handleStemsReorder}>
                    <Droppable droppableId="droppable" direction="vertical">
                      {({ innerRef, droppableProps, placeholder }) => (
                        <Box ref={innerRef} {...droppableProps}>
                          {songId === songParentId &&
                            stems?.map((stem, i) => (
                              <Draggable isDragDisabled={!currentVersion} draggableId={stem.id} index={i} key={stem.id}>
                                {({ innerRef, draggableProps, dragHandleProps }) => (
                                  <StemItem
                                    isReordering={isReorderingStems}
                                    innerRef={innerRef}
                                    draggableProps={draggableProps}
                                    dragHandleProps={dragHandleProps}
                                    key={stem.id}
                                    stem={stem}
                                    isSolo={soloMutedStems?.get(stem.id)?.solo}
                                    isMuted={soloMutedStems?.get(stem.id)?.muted}
                                    canEdit={canEdit}
                                    isOwner={isOwner}
                                    canDownload={canDownload}
                                    playlistEventEmitter={playlistEventEmitter}
                                    playlist={playlist}
                                    setSoloMuted={setSoloMutedStems}
                                    soloMutedStems={soloMutedStems}
                                    index={i}
                                    setIsBackdrop={setIsBackdrop}
                                    isVersionPlayer={versionMode}
                                    play={handleButtonClick}
                                    isStemsPlaying={isStemsPlaying}
                                  />
                                )}
                              </Draggable>
                            ))}
                          {placeholder}
                        </Box>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Grid>

                {showLufs && (
                  <Grid
                    item
                    xs={2}
                    position="relative"
                    sx={{
                      '&:hover .icon-hover': {
                        opacity: 1
                      }
                    }}
                  >
                    <CombinedLufsItem soloMutedStems={soloMutedStems} playlist={playlist} handleCloseLufs={handleCloseLufs} />
                    <Stack direction={'column'}>
                      {stems?.map((stem, index) => (
                        <LufsItem ac={playlist?.ac} key={stem.id} audioBuffer={playlist?.tracks[index]?.buffer} />
                      ))}
                    </Stack>
                  </Grid>
                )}

                <Grid item xs={showLufs ? 5 : 7} position="relative">
                  {!isNewSong && currentVersion && !!stems.length && (
                    <Playlist
                      soloMutedStems={soloMutedStems}
                      versionId={currentVersion!}
                      stems={stems.map(stem => ({ name: stem.name, src: stem.url }))}
                      onStopPlaying={playListStopPlayingHandler}
                      createCommentDialog={createCommentDialog}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </Grid>
        {isSidebarOpened && (
          <MetadataSidebar
            playlist={playlist}
            isSidebarOpened={isSidebarOpened}
            closeSidebar={() => setIsSidebarOpened(false)}
            canEditMetadata={isOwner || canEdit}
          />
        )}
      </Grid>
    </SimpleBar>
  );
}
