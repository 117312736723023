import trackEvent from "../services/trackService";

export const copySharingLink = async (accessShareType: string, folderId: string, songId: string, isEmbed = false) => {
    const isFolderSharing = `isfs=${false}`;

    const urlPath =
      accessShareType === 'invited' ? `/dashboard/${folderId}/${songId}?isEmbed=${isEmbed}` : `/dashboard/${folderId}/${songId}?${isFolderSharing}&isEmbed=${isEmbed}`;
    const urlForSharing = document.location.origin + urlPath;

    await navigator.clipboard.writeText(urlForSharing);
    trackEvent('share', { type: 'copy_link' + (isEmbed ? '_embed' : '') });
  };