import LinkIcon from '@mui/icons-material/Link';
import { Button, Tooltip } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSongSelector } from '../../store/selectors/song.selector';
import { useUserStore } from '../../store/user.store';
import axios from '../../utils/axios';
import { copySharingLink } from '../../utils/copyLink';

type SongLinkCopy = {
  disabled: boolean;
};

export function SongLinkCopy({ disabled }: SongLinkCopy) {
  const getUser = useUserStore(state => state.getUser);
  const { folderId, songId } = useParams();
  const { accessShareType, setAccessShareType, setAccessTypePublic } = useSongSelector();

  useEffect(() => {
    getUser();
    axios.get(`/songs/get-share-status/${songId}`).then(res => {
      setAccessShareType(res.data.isPublic ? 'anyone' : 'invited');
      setAccessTypePublic(res.data.accessType ?? '');
    });
  }, [songId]);

  const handleCopySharingLink = async () => {
    copySharingLink(accessShareType, folderId!, songId!);
  };

  return (
    <Tooltip title={'copy link'}>
      <Button
        disabled={accessShareType === 'invited' || disabled}
        id="basic-button"
        onClick={handleCopySharingLink}
        variant="contained"
        size="small"
        color="secondary"
        disableElevation={true}
        sx={{
          height: { xs: '35px', sm: '40px' },
          width: { xs: '35px', sm: '40px' },
          minWidth: 'unset',
          background: 'rgba(75, 75, 75, 0.5)',
          padding: '0 8px',
          boxShadow: 'none',
          '&.Mui-disabled': {
            background: 'rgb(75, 75, 75, 0.5)'
          }
        }}
      >
        <LinkIcon sx={{ width: '16px', height: '16px', opacity: disabled || accessShareType === 'invited' ? '0.5' : '1' }} />
      </Button>
    </Tooltip>
  );
}
