import { Comment } from '../@types/comment';
import axios from '../utils/axios';

const createComment = async (stemId: string, currentTime: number, commentText: string): Promise<Comment> => {
  return await axios.post(`comments/${stemId}`, { currentTime, commentText }).then(res => res.data);
};

const updateComment = async (commentId: string, commentText: string): Promise<Comment> => {
  return await axios.put(`comments/${commentId}`, { commentText }).then(res => res.data);
};

const deleteComment = async (commentId: string) => {
  await axios.delete(`comments/${commentId}`);
};

const getCommentsByVersion = async (versionId: string) => {
  return await axios.get(`comments/${versionId}`).then(res => res.data);
};

const getCommentMessages = async (commentId: string) => {
  return await axios.get(`comments/${commentId}`).then(res => res.data);
};

const commentService = {
  createComment,
  updateComment,
  deleteComment,
  getCommentsByVersion,
  getCommentMessages
};

export default commentService;
