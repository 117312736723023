import { useAuth0 } from '@auth0/auth0-react';
import DocViewer, { DocViewerRenderers, IDocument } from '@cyntler/react-doc-viewer';
import '@cyntler/react-doc-viewer/dist/index.css';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import trackEvent from '../services/trackService';
import { useSongSelector } from '../store/selectors/song.selector';
import { useUploadFilesSelector } from '../store/selectors/uploadFiles.selector';

const supportedExtensions: Set<string> = new Set([
  'bmp',
  'csv',
  'odt',
  'doc',
  'docx',
  'gif',
  'htm',
  'html',
  'jpg',
  'jpeg',
  'pdf',
  'png',
  'ppt',
  'pptx',
  'tiff',
  'txt',
  'xls',
  'xlsx',
  'mp4',
  'webp'
]);

function isFileSupported(filename: string): boolean {
  // Extract the file extension (case-insensitive)
  const extension = filename.split('.').pop()?.toLowerCase();
  if (!extension) {
    return false; // No extension found
  }

  return supportedExtensions.has(extension);
}

export default function FilePreview() {
  const { folderId, songId } = useParams();
  const { uploadingSongs } = useUploadFilesSelector();
  const { user } = useAuth0();
  const [docs, setDocs] = useState<IDocument[]>([]);
  const {
    songVersions,
    currentVersion,
    song,
    songParentId,
    stems,
    getSongVersions,
    getSongWithStems,
    clearState,
    clearSong,
    clearStems,
    reorderStems,
    createdAt,
    uploadedBy,
    bpm,
    setSong,
    addStems,
    isOriginalStems,
    setIsOriginalStems,
    isNewSong,
    isCompressedExists,
    setIsNewSong,
    changeMultiTrackMode
  } = useSongSelector();
  const [cannotRender, setCannotRender] = useState(false);
  useEffect(() => {
    setDocs([]);
    if (isNewSong) {
      setIsNewSong(false);
      if (!isFileSupported(stems?.[0]?.name ?? '')) {
        setCannotRender(true);
      } else {
        setCannotRender(false);
      }
      stems?.[0]?.url && setDocs([{ uri: stems?.[0]?.url }]);
    } else {
      user && trackEvent('song_view', { songId: songId!, songName: song?.name ?? '', folderId: folderId ?? '' });
      clearSong();
      clearStems();
      const getDataAndRender = async () => {
        await getSongVersions(songId!);
        const uploadState = uploadingSongs.find(uploadSong => uploadSong.songId === songId);
        await getSongWithStems(uploadState?.stems, uploadState?.song).then(async files => {
          if (!isFileSupported(files?.stems?.[0]?.name ?? '')) {
            setCannotRender(true);
          } else {
            setCannotRender(false);
          }
          if (files?.stems?.[0]?.url) {
            setDocs([
              {
                uri: files?.stems?.[0]?.url
              }
            ]);
          }
        });
      };

      getDataAndRender();
    }
    return () => {};
  }, [songId, folderId]);
  return (
    <>
      {cannotRender ? (
        <Box
          sx={{
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}
        >
          File type is not supported for preview
        </Box>
      ) : (
        <DocViewer
          prefetchMethod="GET"
          style={{ height: '100vh', overflow: 'scroll' }}
          config={{
            header: { disableHeader: true }
          }}
          pluginRenderers={DocViewerRenderers}
          documents={docs}
        />
      )}
    </>
  );
}
