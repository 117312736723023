import { useAuth0 } from '@auth0/auth0-react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  Avatar,
  AvatarGroup,
  Backdrop,
  Box,
  ButtonBase,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { FileType } from '../../@types/files';
import { Folder, SidebarFolder } from '../../@types/folders';
import { NotificationType } from '../../@types/notifications';
import { ReactComponent as LogoutIcon } from '../../assets/icons/logoutIcon.svg';
import { ReactComponent as PlansIcon } from '../../assets/icons/plansIcon.svg';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';
import '../../pages/resizeHandle.css';
import authService from '../../services/authService';
import folderService from '../../services/folderService';
import notificationService from '../../services/notificationService';
import sharedService from '../../services/sharingService';
import songsService from '../../services/songsService';
import trackEvent from '../../services/trackService';
import { defaultFolderName } from '../../store/folders.store';
import { useFoldersSelector } from '../../store/selectors/folders.selector';
import { useSongSelector } from '../../store/selectors/song.selector';
import { useUploadFilesSelector } from '../../store/selectors/uploadFiles.selector';
import { useSidebarStore } from '../../store/sidebar.store';
import { useUserStore } from '../../store/user.store';
import { formatDateMDY } from '../../utils/date';
import { calculateFolderSize, checkFileType, FolderNode, getFile, readDirectory } from '../../utils/fileUtils';
import { generateId } from '../../utils/generateId';
import { processUnZipFile } from '../../utils/useUnzipWorker';
import CreateDropDownMenu from './Menus/CreateDropDownMenu';
import FolderDropDown from './components/FolderDropDown';
import FolderDropDownItemsList from './components/FolderDropDownItemsList';
import './scrollbar.css';

export default function DashboardLayout() {
  const { enqueueSnackbar } = useSnackbar();
  const [isSidebarHidden, setIsSidebarHidden] = useState(false);
  const user = useUserStore(state => state.user);
  const getUser = useUserStore(state => state.getUser);
  const getUsedStorage = useUserStore(state => state.getUsedStorage);
  const usedStorage = useUserStore(state => state.usedStorage);
  const getUserPlan = useUserStore(state => state.getUserPlan);
  const maxSizeStorage = useUserStore(state => state.maxSizeStorage);
  const [movingSongToFolder, setMovingSongToFolder] = useState<null | { folderName: string; songName: string }>(null);
  const {
    folders,
    notFilteredFolders,
    isFoldersLoading,
    getFolders,
    defaultFolder,
    moveToRootFolder,
    moveFolderSong,
    setFolders,
    openFolder,
    createFolder
  } = useFoldersSelector();
  const containerRef = useRef<HTMLDivElement>();
  const navigate = useNavigate();
  const { folderId, songId } = useParams();
  const location = useLocation();
  const [highlightedSong, setHighlightedSong] = useState('');
  const [isBackdrop, setIsBackdrop] = useState(false);
  const isSidebarOpened = useSidebarStore(state => state.isSidebarOpened);
  const toggleSidebarMobile = useSidebarStore(state => state.toggleSidebar);
  const setSidebarWidth = useSidebarStore(state => state.setSidebarWidth);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { user: userAuth0, logout, loginWithRedirect } = useAuth0();
  const [isEmailVerified, setIsEmailVerified] = useState<undefined | boolean>(undefined);
  const params = new URLSearchParams(location.search);
  const isPublicLocation = !!params.get('isfs')?.length;
  const hasMounted = useRef(false);
  let storageLimit = maxSizeStorage > 1000 ? maxSizeStorage / 1000 + ' TB' : maxSizeStorage + ' GB';
  let timer: string | number | NodeJS.Timeout | undefined;

  const { createNewSong } = useSongSelector();
  const { setUploadData, createParentSong, lastQueue, currentPreparedSharedUsers } = useUploadFilesSelector();

  const firstPanelRef = useRef<any>(null);
  const secondPanelRef = useRef<any>(null);
  const sidebarWidth = useSidebarStore(state => state.sidebarWidth);

  const toggleSidebar = () => {
    if (!isSidebarHidden) {
      firstPanelRef.current?.collapse();
      secondPanelRef.current?.resize(100);
    } else {
      firstPanelRef.current?.expand();
    }
    setIsSidebarHidden(!isSidebarHidden);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const invitation = searchParams.get('invitation');
    const organization = searchParams.get('organization');

    if (invitation && organization) {
      loginWithRedirect({
        authorizationParams: {
          invitation,
          organization,
          redirectUri: window.location.origin,
          appState: {
            returnTo: '/dashboard'
          }
        }
      });
    }
  }, [location, loginWithRedirect]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (user || userAuth0) {
      getUserPlan();
      getUsedStorage();
      timeout = setInterval(() => getUsedStorage(), 5000);
    }

    return () => clearTimeout(timeout);
  }, [userAuth0, user]);

  useEffect(() => {
    getUser();

    if (userAuth0) {
      authService.checkEmailVerification().then(emailVerification => setIsEmailVerified(emailVerification));
    }

    getFolders().then(async folders => {
      if (!folders.filter(f => f.name !== 'example').length) {
        await createFolder(defaultFolderName, undefined, user?.name || user?.email);

        if (user) {
          navigate('/dashboard');
        }
      }
    });
  }, [userAuth0]);

  useEffect(() => {
    if (defaultFolder) {
      const isFolderShare = params.get('isfs');

      if (isFolderShare) {
        localStorage.setItem('isfs', isFolderShare);
      }

      if (isFolderShare?.length) {
        if (folderId && isFolderShare === 'true') {
          if (notFilteredFolders.find(f => f.id === folderId)) {
            return;
          }

          sharedService.getFolderAccessType(folderId).then(accessType => {
            folderService.getFolderById(folderId).then(folder => {
              const addIsOpenProperty = (folders: Folder[]): SidebarFolder[] => {
                return folders.map(folder => ({
                  ...folder,
                  isOpen: false,
                  children: addIsOpenProperty(folder.children)
                }));
              };

              const modifiedFolders = addIsOpenProperty([folder]);

              function findFirstSong(folders: SidebarFolder[]): { folderId: string; songId: string } | null {
                for (const folder of folders) {
                  if (folder.songs.length > 0) {
                    return { folderId: folder.id, songId: folder.songs[0].id };
                  }

                  if (folder.children.length > 0) {
                    const result = findFirstSong(folder.children);
                    if (result) {
                      return result;
                    }
                  }
                }

                return null;
              }

              const firstSong = findFirstSong(modifiedFolders);

              setFolders([...notFilteredFolders, ...modifiedFolders.map(f => ({ ...f, name: f.name, isOpen: true }))]);

              if (firstSong) {
                navigate(`/dashboard/${firstSong.folderId}/${firstSong.songId}`);
                setHighlightedSong(firstSong?.songId);
              }
            });
          });
        } else if (songId && isFolderShare === 'false') {
          if (notFilteredFolders.find(f => f.id === defaultFolder?.id)?.songs.find(s => s.id === songId)) {
            return;
          }

          songsService.getSongById(songId!).then(song => {
            setFolders(
              notFilteredFolders.map(f => {
                if (f.id === defaultFolder?.id) {
                  f.songs.push(song);
                }

                return f;
              })
            );

            setHighlightedSong(song.id ?? '');
          });
        }
      }
    }
  }, [defaultFolder]);

  useEffect(() => {
    if (typeof isEmailVerified === 'boolean' && !isEmailVerified) {
      navigate('/email-verification');
    }
  }, [isEmailVerified]);

  useEffect(() => {
    if (params.get('open') === 'true') {
      setIsSidebarHidden(false);

      if (folders) {
        if (folderId) openFolder(folderId);
      }
    }
  }, [location, folders.length]);

  useEffect(() => {
    if (hasMounted.current) {
      toggleSidebarMobile();
    } else {
      hasMounted.current = true;
    }
  }, [location.pathname]);

  const triggerNavigating = async (
    song?: File,
    stems?: File[],
    fId?: string,
    toggleNavigate?: boolean,
    songName?: string,
    fileType?: FileType
  ) => {
    const parentSongId = generateId();
    const folderId = fId ?? defaultFolder?.id ?? '';

    const nameOfSong = song?.name ?? songName ?? stems?.at(0)?.name ?? 'DEMO_' + formatDateMDY(new Date());
    const parentSong = await createParentSong(folderId, parentSongId, nameOfSong, fileType ?? FileType.Audio);
    const firstVersion = await songsService.addSongVersion(parentSongId, nameOfSong, '');

    firstVersion.songName = nameOfSong;

    const stemsIds =
      stems?.map(
        _ =>
          'stem_' +
          generateId() +
          '_' +
          new Date()
            .toISOString()
            .replace(/[-T:.]/g, '_')
            .slice(0, -1) +
          (fileType === FileType.Audio ? '_audio' : '_other')
      ) ?? [];

    if (toggleNavigate) {
      createNewSong(
        song
          ? {
              id:
                'song_' +
                generateId() +
                '_' +
                new Date()
                  .toISOString()
                  .replace(/[-T:.]/g, '_')
                  .slice(0, -1),
              mime: song.type,
              name: song.name,
              createdAt: new Date().toString(),
              updatedAt: new Date().toString(),
              uploadedBy: user?.name,
              size: song.size,
              order: 0,
              url: URL.createObjectURL(song)
            }
          : null,
        stems?.map((stem, i) => ({
          id: stemsIds[i],
          songParentId: parentSongId,
          mime: stem.type,
          name: stem.name,
          createdAt: new Date().toString(),
          updatedAt: new Date().toString(),
          uploadedBy: user?.name,
          size: stem.size,
          order: i + 1,
          url: URL.createObjectURL(stem)
        })) ?? [],
        [firstVersion],
        firstVersion.id,
        parentSongId,
        '',
        new Date().toString(),
        new Date().toString(),
        user?.name,
        0,
        currentPreparedSharedUsers
      );
    }

    (song || stems) &&
      setUploadData(
        song ? { file: song, progress: 0, estimatedTime: 0, versionId: firstVersion.id! } : null,
        stems?.map((stem, i) => ({
          file: stem,
          progress: 0,
          estimatedTime: 0,
          stemId: stemsIds[i],
          queueId: lastQueue + 1,
          versionId: firstVersion.id!,
          folderName: songName,
          order: i + 1
        })) ?? [],
        parentSongId,
        folderId,
        fileType ?? FileType.Audio,
        true
      );

    notificationService.createNotification(NotificationType.UploadSong, parentSongId, folderId);

    if (toggleNavigate) {
      navigate(`/dashboard`);
      timer = setTimeout(() => navigate(`/dashboard/${folderId}/${parentSongId}${fileType === FileType.Other ? '/file' : ''}`), 20);
    }
  };

  const traverseAndCreateFolders = async (folders: FolderNode[], parentFolderId?: string, nav?: boolean): Promise<any> => {
    let navigate = nav === undefined ? true : nav;

    for (const folder of folders) {
      const otherFiles: File[] = [];
      const audioFiles: File[] = [];
      for (const file of folder.files) {
        if ((await checkFileType(file)) === FileType.Audio) {
          audioFiles.push(file);
        } else if ((await checkFileType(file)) === FileType.Other) {
          otherFiles.push(file);
        }
      }
      let withoutFolder = !folder.subFolders.length && !!folder.files.length && !otherFiles.length;
      let createdFolder: SidebarFolder | undefined = undefined;

      if (folder.id !== 'no_folder' && !withoutFolder) {
        createdFolder = await createFolder(folder.name, parentFolderId, user?.name || user?.email);
      }

      if (folder.files.length) {
        if (audioFiles.length) {
          await triggerNavigating(
            undefined,
            audioFiles,
            createdFolder?.id! ?? parentFolderId,
            navigate,
            (withoutFolder || !folder.subFolders.length) && folder.id !== 'no_folder' ? folder.name : undefined,
            FileType.Audio
          );

          navigate = false;
        }
        if (otherFiles.length) {
          for (const other of otherFiles) {
            await triggerNavigating(undefined, [other], createdFolder?.id! ?? parentFolderId, navigate, undefined, FileType.Other);
            navigate = false;
          }
        }
      }

      await traverseAndCreateFolders(folder.subFolders, createdFolder?.id!, navigate);
    }
  };

  function sortFolderNode(folderNode: FolderNode): FolderNode {
    folderNode.files.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));

    folderNode.subFolders.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));

    folderNode.subFolders.forEach(sortFolderNode);

    return folderNode;
  }

  const uploadStems = async (fileList?: FileList, fileSystemEntry?: FileSystemEntry[]) => {
    const folderTree: FolderNode[] = [];
    setIsBackdrop(true);

    if (fileSystemEntry?.length) {
      const filePromises = fileSystemEntry.map(async entry => {
        if (entry.isDirectory) {
          const folderNode = await readDirectory(entry as FileSystemDirectoryEntry);

          folderTree.push(folderNode);
        } else {
          const file = await getFile(entry as FileSystemFileEntry);
          const mimeType = file.type || 'application/octet-stream';

          if (mimeType === 'application/zip') {
            const stems = await processUnZipFile(file);

            folderTree.push(...stems);
          } else {
            if (!folderTree.length) {
              folderTree.push({ id: 'no_folder', name: 'no folder', files: [], subFolders: [] });
            }

            folderTree?.at(0)?.files.push(file);
          }
        }
      });

      await Promise.all(filePromises);
    } else if (fileList?.length) {
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];

        if (file.type === 'application/zip') {
          const stems = await processUnZipFile(file);

          folderTree.push(...stems);
        } else {
          if (!folderTree.length) {
            folderTree.push({ id: 'no_folder', name: 'no folder', files: [], subFolders: [] });
          }

          folderTree?.at(0)?.files.push(file);
        }
      }
    }

    setIsBackdrop(false);

    const size = calculateFolderSize(folderTree);

    if (!(size + usedStorage > maxSizeStorage)) {
      trackEvent('batch-upload', { type: 'new-song' });

      const sortedFolderTree = folderTree.map(folder => sortFolderNode(folder));

      await traverseAndCreateFolders(sortedFolderTree);
    } else {
      enqueueSnackbar("You haven't enough space. Update your plan.", { variant: 'error' });

      navigate('/plans');
    }
  };

  const handleDropMusicToDefaultFolder = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    const items = e.dataTransfer.items;
    const fileSystemEntries: FileSystemEntry[] = [];

    Array.from(items).forEach(file => {
      const entry = file.webkitGetAsEntry();

      if (entry) {
        fileSystemEntries.push(entry);
      }
    });

    if (fileSystemEntries.length) {
      uploadStems(undefined, fileSystemEntries);
      return;
    }

    const draggedData = JSON.parse(e.dataTransfer.getData('text/plain'));

    if (draggedData.folderId === defaultFolder?.id) return;

    if (!defaultFolder?.id) return;

    if (!!draggedData.folderName) {
      await moveToRootFolder(draggedData.folderId, user?.name || user?.email);
    } else if (!!draggedData.songName) {
      setMovingSongToFolder({ folderName: defaultFolder?.name, songName: draggedData.songName });

      await moveFolderSong(
        { songId: draggedData.songId, currentFolderId: draggedData.folderId, newFolderId: defaultFolder?.id },
        user?.name || user?.email
      );

      enqueueSnackbar('Song moved successfully', { variant: 'success' });
      setMovingSongToFolder(null);
    }
  };

  const handleLogout = async () => {
    await logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={!!movingSongToFolder}
        sx={{
          '& .MuiSnackbarContent-root': {
            background: 'linear-gradient(270deg, #008EF3 0%, #16E3F5 100%)'
          }
        }}
        message={"Moving song '" + movingSongToFolder?.songName + "' to " + "'" + movingSongToFolder?.folderName + "'" + ' folder...'}
      />
      <Backdrop sx={theme => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })} open={isBackdrop}>
        <CircularProgress sx={{ color: '#008EF3' }} />
      </Backdrop>
      {isMobile ? (
        <>
          <Drawer
            open={isSidebarOpened}
            onClose={toggleSidebarMobile}
            PaperProps={{ sx: { backgroundColor: '#151515', backgroundImage: 'unset' } }}
          >
            <Box
              sx={{
                width: 340,
                height: '100%',
                maxHeight: '100dvh',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                pb: 2,
                position: 'relative'
              }}
              role="presentation"
            >
              <SimpleBar
                style={{
                  maxHeight: 'calc(100dvh - 10%)',
                  width: '100%'
                }}
              >
                <List>
                  <Grid container p={1.25}>
                    <Grid item xs>
                      <Grid container columnSpacing={2.25} alignItems={'center'}>
                        <Grid item xs={'auto'}>
                          <AvatarGroup max={2}>
                            <Avatar sx={{ width: 28, height: 28 }}>
                              <AccountCircleIcon />
                            </Avatar>
                          </AvatarGroup>
                        </Grid>
                        <Grid item xs>
                          <Stack direction={'column'}>
                            <Typography
                              variant="body1"
                              fontWeight={400}
                              fontSize={16}
                              sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                            >
                              {userAuth0?.name ?? userAuth0?.email}
                            </Typography>
                            {userAuth0?.email && userAuth0.name !== userAuth0?.email ? (
                              <Typography fontSize={14} color="text.secondary">
                                {userAuth0?.email}
                              </Typography>
                            ) : (
                              <Typography onClick={() => loginWithRedirect()}> Log in/Sign up</Typography>
                            )}
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={'auto'}></Grid>
                  </Grid>
                  <Divider />
                  <Box>
                    <ListItem disablePadding>
                      <ListItemButton sx={{ paddingBlock: 1, paddingInline: 2 }} onClick={() => navigate('/dashboard')}>
                        <UploadIcon width={24} height={24} />
                        <Typography fontSize={18} ml={0.75} lineHeight={'24px'}>
                          Upload
                        </Typography>
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton sx={{ paddingBlock: 1, paddingInline: 2 }} onClick={() => navigate('/faqs')}>
                        <Typography fontSize={18} ml={0.75} lineHeight={'24px'}>
                          How it works
                        </Typography>
                      </ListItemButton>
                    </ListItem>

                    <ListItem disablePadding>
                      <ListItemButton sx={{ paddingBlock: 1, paddingInline: 2 }} onClick={() => navigate('/plans')}>
                        <ListItemIcon style={{ minWidth: 'unset', marginRight: '4px' }}>
                          <PlansIcon width={24} height={24} />
                        </ListItemIcon>
                        <Typography fontSize={18} ml={0.75} lineHeight={'24px'}>
                          Plans
                        </Typography>
                      </ListItemButton>
                    </ListItem>

                    {userAuth0 && (
                      <ListItem disablePadding>
                        <ListItemButton sx={{ paddingBlock: 1, paddingInline: 2 }} onClick={handleLogout}>
                          <ListItemIcon style={{ minWidth: 'unset', marginRight: '4px' }}>
                            <LogoutIcon width={24} height={24} />
                          </ListItemIcon>
                          <Typography fontSize={18} ml={0.75} lineHeight={'24px'}>
                            Log out
                          </Typography>
                        </ListItemButton>
                      </ListItem>
                    )}
                  </Box>
                  <Divider />
                  <Stack sx={{ backgroundColor: '#151515' }} height={1} borderRadius={2} flexDirection="column">
                    <Stack p={2} flexGrow={1}>
                      <CreateDropDownMenu />
                      <Grid maxWidth={'100% !important'} item onDragOver={e => e.preventDefault()} onDrop={handleDropMusicToDefaultFolder}>
                        {folders.filter(f => f.name === 'example').length ? (
                          <FolderDropDownItemsList
                            level={0}
                            highlightedSongId={highlightedSong}
                            setHighlightedSongId={setHighlightedSong}
                            folder={folders.filter(f => f.name === 'example').at(0)!}
                            isSharingFiles={isPublicLocation}
                          />
                        ) : null}
                      </Grid>
                      <Grid container direction={'column'} flexGrow={1} mt={2.5}>
                        <Box ref={containerRef} />
                        {isFoldersLoading ? (
                          <Grid item>
                            <Typography mt={1}>Loading...</Typography>
                          </Grid>
                        ) : null}
                        <Grid rowSpacing={1.25} container direction={'column'}>
                          {folders
                            ?.filter(f => f.name !== 'example')
                            ?.map(folder => (
                              <Grid item key={folder.id} maxWidth={'100% !important'}>
                                <FolderDropDown
                                  folder={folder}
                                  setMovingSongToFolder={setMovingSongToFolder}
                                  highlightedSongId={highlightedSong}
                                  setHighlightedSongId={setHighlightedSong}
                                  isSharingFolder={folder.id === 'shared_folder' || isPublicLocation}
                                  setIsBackdrop={setIsBackdrop}
                                />
                              </Grid>
                            ))}
                          <Grid
                            maxWidth={'100% !important'}
                            item
                            onDragOver={e => e.preventDefault()}
                            onDrop={handleDropMusicToDefaultFolder}
                          >
                            {defaultFolder ? (
                              <FolderDropDownItemsList
                                level={0}
                                highlightedSongId={highlightedSong}
                                setHighlightedSongId={setHighlightedSong}
                                folder={defaultFolder!}
                                isSharingFiles={isPublicLocation}
                              />
                            ) : null}
                          </Grid>
                        </Grid>

                        <Grid onDragOver={e => e.preventDefault()} onDrop={handleDropMusicToDefaultFolder} flexGrow={1}></Grid>
                      </Grid>
                    </Stack>
                  </Stack>
                </List>
              </SimpleBar>
              <Grid
                container
                alignItems={'center'}
                justifyContent={'space-between'}
                position={'absolute'}
                bottom={10}
                sx={{ backgroundColor: '#151515' }}
              >
                <Grid item xs={6}>
                  <Typography sx={{ padding: '8px 8px', opacity: '50%', fontSize: '14px' }}>
                    {usedStorage} GB of {storageLimit} used
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <ButtonBase
                    sx={{
                      py: 0.875,
                      px: 1.5,
                      border: '1px solid #494949',
                      borderRadius: 1,
                      background: `linear-gradient(#131414 0 0) padding-box, linear-gradient(to right, #18C7D7, #0E8EF3) border-box`,
                      borderImage: 'linear-gradient(#008EF3, #16E1F5)'
                    }}
                    onClick={event => {
                      event.preventDefault();

                      navigate('/plans');
                    }}
                  >
                    <Typography
                      color="transparent"
                      sx={{
                        fontSize: '14px',
                        background: 'linear-gradient(266deg, #008EF3 8.39%, rgba(22, 227, 245, 0.9) 88.47%)',
                        '-webkit-background-clip': 'text',
                        backgroundClip: 'text'
                      }}
                    >
                      Get more storage
                    </Typography>
                  </ButtonBase>
                </Grid>
              </Grid>
            </Box>
          </Drawer>
          <Outlet />
        </>
      ) : null}
      {!isMobile ? (
        <Grid container pl={1} pt={1} pb={1} flexGrow={1} margin={0}>
          <PanelGroup direction="horizontal" autoSave={''} style={{ height: '100%' }}>
            <Panel
              ref={firstPanelRef}
              onResize={size => setSidebarWidth((size / 100) * (window.innerWidth - 32))}
              collapsible
              defaultSize={20}
              collapsedSize={0}
              minSize={0}
              style={{ minWidth: '0', height: '100%' }}
            >
              <Grid item height={1}>
                <Stack sx={{ backgroundColor: '#151515' }} height={1} borderRadius={2} flexDirection="column">
                  <Stack p={2} flexGrow={1}>
                    <CreateDropDownMenu />
                    <Grid maxWidth={'100% !important'} item onDragOver={e => e.preventDefault()} onDrop={handleDropMusicToDefaultFolder}>
                      {folders.filter(f => f.name === 'example').length ? (
                        <FolderDropDownItemsList
                          level={0}
                          highlightedSongId={highlightedSong}
                          setHighlightedSongId={setHighlightedSong}
                          folder={folders.filter(f => f.name === 'example').at(0)!}
                          isSharingFiles={isPublicLocation}
                        />
                      ) : null}
                    </Grid>
                    <Grid container style={{ maxHeight: 'calc(100vh - 230px)' }} direction={'column'} flexGrow={1} mt={1.25}>
                      <Box ref={containerRef} />
                      {isFoldersLoading ? (
                        <Grid item>
                          <Typography mt={1}>Loading...</Typography>
                        </Grid>
                      ) : null}
                      <SimpleBar
                        style={{
                          maxHeight: 'calc(100vh - 230px)',
                          width: '100%'
                        }}
                      >
                        <Grid rowSpacing={1.25} container direction={'column'}>
                          {folders
                            ?.filter(f => f.name !== 'example')
                            ?.map(folder => (
                              <Grid item key={folder.id} maxWidth={'100% !important'}>
                                <FolderDropDown
                                  folder={folder}
                                  setMovingSongToFolder={setMovingSongToFolder}
                                  highlightedSongId={highlightedSong}
                                  setHighlightedSongId={setHighlightedSong}
                                  isSharingFolder={folder.id === 'shared_folder' || isPublicLocation}
                                  setIsBackdrop={setIsBackdrop}
                                />
                              </Grid>
                            ))}

                          <Grid
                            maxWidth={'100% !important'}
                            item
                            onDragOver={e => e.preventDefault()}
                            onDrop={handleDropMusicToDefaultFolder}
                          >
                            {defaultFolder ? (
                              <FolderDropDownItemsList
                                level={0}
                                highlightedSongId={highlightedSong}
                                setHighlightedSongId={setHighlightedSong}
                                folder={defaultFolder!}
                                isSharingFiles={isPublicLocation}
                              />
                            ) : null}
                          </Grid>
                        </Grid>
                      </SimpleBar>
                      <Grid onDragOver={e => e.preventDefault()} onDrop={handleDropMusicToDefaultFolder} flexGrow={1}></Grid>
                    </Grid>
                    <Grid mt={5} container alignItems="center" justifyContent="space-between">
                      <Grid item xs={'auto'}>
                        <Typography sx={{ padding: '4px 8px', opacity: '50%', fontSize: '12px' }}>
                          {usedStorage} GB of {storageLimit} used
                        </Typography>
                      </Grid>
                      <Grid item xs={'auto'}>
                        <ButtonBase
                          sx={{
                            py: 0.875,
                            px: 1.5,
                            border: '1px solid #494949',
                            borderRadius: 1,
                            background: `linear-gradient(#131414 0 0) padding-box, linear-gradient(to right, #18C7D7, #0E8EF3) border-box`,
                            borderImage: 'linear-gradient(#008EF3, #16E1F5)'
                          }}
                          onClick={event => {
                            event.preventDefault();

                            navigate('/plans');
                          }}
                        >
                          <Typography
                            color="transparent"
                            sx={{
                              fontSize: '14px',
                              background: 'linear-gradient(266deg, #008EF3 8.39%, rgba(22, 227, 245, 0.9) 88.47%)',
                              '-webkit-background-clip': 'text',
                              backgroundClip: 'text'
                            }}
                          >
                            Get more storage
                          </Typography>
                        </ButtonBase>
                      </Grid>
                    </Grid>
                  </Stack>
                </Stack>
              </Grid>
            </Panel>
            <PanelResizeHandle className="resizeHandle" style={{ maxWidth: '8px' }} hitAreaMargins={{ coarse: 10, fine: 5 }} />
            <Panel ref={secondPanelRef} defaultSize={80} minSize={5} style={{ height: '100%' }}>
              <Grid item pr={1} height={1} width={'100%'}>
                <Stack height={1} borderRadius={1}>
                  <Tooltip title={'Files & Folders'}>
                    <IconButton
                      disableRipple
                      disableFocusRipple
                      sx={{
                        position: 'absolute',
                        left: '30px',
                        top: '88%',
                        zIndex: 999,
                        width: '30px',
                        height: '30px',
                        backgroundColor: '#000000'
                      }}
                      onClick={() => {
                        toggleSidebar();
                        toggleSidebarMobile();
                      }}
                    >
                      {isSidebarHidden ? <ArrowForwardIosIcon fontSize={'small'} /> : <ArrowBackIosNewIcon fontSize={'small'} />}
                    </IconButton>
                  </Tooltip>
                  <Outlet />
                </Stack>
              </Grid>
            </Panel>
          </PanelGroup>
        </Grid>
      ) : null}
    </>
  );
}
