import { GlobalStyles as MUIGlobalStyles } from '@mui/material';
import { useSongSelector } from '../store/selectors/song.selector';
import customCursor from '../assets/icons/commentIcon.svg';

export function GlobalStyles() {
  const { isCommentMode } = useSongSelector();

  return (
    <MUIGlobalStyles
      styles={{
        '*': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box'
        },
        html: {
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch'
        },
        body: {
          width: '100%',
          height: '100%',
          backgroundColor: 'black',
          cursor: isCommentMode ? `url(${customCursor}), auto` : 'default'
        }
      }}
    />
  );
}
