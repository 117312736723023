import { Box, Button, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import React, { useState } from 'react';
import DownloadSongFilesDialog from '../../components/DownloadSongFilesDialog';
import { useSongSelector } from '../../store/selectors/song.selector';
import { ReactComponent as Download } from '../../assets/icons/download.svg';

export type SongDownLoadProps = {
  disabled: boolean;
  small?: boolean;
};

export function SongDownload({ disabled, small = false }: SongDownLoadProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isDownloadSongFilesDialogOpen, setIsDownloadSongFilesDialogOpen] = useState<boolean>(false);
  const { currentVersion, songVersions, song } = useSongSelector();

  return (
    <>
      {isDownloadSongFilesDialogOpen && (
        <DownloadSongFilesDialog
          versionId={currentVersion!}
          songName={song?.name ?? songVersions.find(v => v.id === currentVersion)?.songName ?? 'Enter song name'}
          onClose={() => setIsDownloadSongFilesDialogOpen(false)}
          open={isDownloadSongFilesDialogOpen}
        />
      )}

      <Box>
        <Tooltip title={'Download stems and/or song'}>
          <Button
            disabled={disabled}
            size="small"
            variant="contained"
            color="secondary"
            onClick={() => setIsDownloadSongFilesDialogOpen(true)}
            disableElevation={true}
            sx={
              small
                ? {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minWidth: 'unset',
                  width: isMobile ? '40px' : '28px',
                  height: isMobile ? '40px' : '28px',
                    padding: '0 8px',
                    boxShadow: 'none',
                    '&.Mui-disabled': {
                      background: 'rgb(75, 75, 75, 0.5)'
                    }
                  }
                : {
                    height: { xs: '35px', sm: '40px' },
                    width: { xs: '35px', sm: '40px' },
                    minWidth: 'unset',
                    padding: '0 8px',
                    boxShadow: 'none',
                    '&.Mui-disabled': {
                      background: 'rgb(75, 75, 75, 0.5)'
                    }
                  }
            }
          >
            <Download opacity={disabled ? '0.5' : '1'} />
          </Button>
        </Tooltip>
      </Box>
    </>
  );
}
