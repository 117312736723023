import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { Button, CircularProgress, Grid, IconButton, Slider, Stack, Tooltip, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import hash from 'object-hash';
import { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { AudioFile, SoloMutedStems } from '../@types/songs';
import { Playlist } from '../components/multitrack/Playlist';
import TimeScale from '../components/multitrack/Timescale';
import { StemItem } from '../components/StemItem';
import { SongDownload } from '../sections/mainSongPage/SongDownload';
import { usePlaylistStore } from '../store/players.store';
import { useSongSelector } from '../store/selectors/song.selector';
import theme from '../theme/theme';
import { generateId } from '../utils/generateId';

function createObjectUrl(input: Blob | { url: string } | File) {
  // Check if the input is a Blob or File
  if (input instanceof Blob) {
    const objectUrl = URL.createObjectURL(input);
    return objectUrl;
  } else {
    return input.url;
  }
}

export function Widget() {
  const { currentVersion, stems, reorderStems, bpm, isNewSong, setIsNewSong, createNewSong } = useSongSelector();
  const [isReorderingStems, setIsReorderingStems] = useState(false);
  const playlistState = usePlaylistStore(state => state.playListStates.find(playlistState => playlistState.versionId === currentVersion));
  const playlistStates = usePlaylistStore(state => state.playListStates);
  const [isStemsPlaying, setIsStemsPlaying] = useState(false);
  const [soloMutedStems, setSoloMutedStems] = useState<Map<string, SoloMutedStems>>(new Map());
  const [colors, setColors] = useState<any>({});
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  let playlist: any;
  let waveSurfer: any;
  let isSongRendered;
  let stemsCountForLoading: number = 0;
  let loadedStemsCount;
  let isStemsRendered: boolean = false;
  let playlistEventEmitter: any;

  if (playlistState) {
    playlist = playlistState.playlist;
    waveSurfer = playlistState.waveSurfer;
    isSongRendered = playlistState.isSongRendered;
    stemsCountForLoading = playlistState.stemsCountForLoading;
    loadedStemsCount = playlistState.loadedStemsCount;
    isStemsRendered = playlistState.isStemsRendered;
    playlistEventEmitter = playlistState.eventEmitter;
  }
  const setStemsCountForLoading = usePlaylistStore(state => state.setStemsCountForLoading);
  const init = usePlaylistStore(state => state.init);
  const [zoomLevel, setZoomLevel] = useState<number>(0);
  const [isStemsLoaded, setIsStemsLoaded] = useState(false);
  const handleStemsReorder = async (result: DropResult) => {
    await reorderStems(result);
    if (playlist) {
      const sourceStem = playlist.tracks[result.source.index];
      playlist.tracks.splice(result.source.index, 1);
      playlist.tracks.splice(result.destination?.index ?? 0, 0, sourceStem);
      playlist.drawRequest();
    }
    setIsReorderingStems(false);
  };
  const handleZoomSliderChange = (event: Event, value: number | number[], activeThumb: number) => {
    setZoomLevel(value as number);
  };
  const rerenderWithCache = (cachedStems: AudioFile[]) => {
    setSoloMutedStems(() => cachedStems?.reduce((map, stem) => map.set(stem.id, { id: stem.id, solo: false, muted: false }), new Map()));
  };
  useEffect(() => {
    const playListState = init(currentVersion!);
    setIsStemsLoaded(false);
    playlistStates.forEach(playlistState => playlistState?.eventEmitter.emit('pause'));
    setSoloMutedStems(new Map());
    setIsStemsPlaying(false);
    setStemsCountForLoading(stems.length, currentVersion!);
    setIsNewSong(false);
    if (playListState?.soloMutedStems) {
      setSoloMutedStems(playListState?.soloMutedStems);
    } else {
      rerenderWithCache(stems);
    }
    setIsStemsLoaded(true);
  }, [currentVersion]);
  useEffect(() => {
    if (playlist) {
      playlist.renderTimeScale = function () {
        const controlWidth = this.controls.show ? this.controls.width : 0;
        const timeScale = new TimeScale(
          this.duration,
          this.scrollLeft,
          this.samplesPerPixel,
          this.sampleRate,
          this.colors,
          controlWidth,
          bpm || 0
        );
        return timeScale.render();
      };
      playlist.drawRequest();
    }
  }, [playlist]);

  useEffect(() => {
    // Function to handle messages received from the iframe
    const handleIframeMessage = async (event: MessageEvent) => {
      // Check the origin of the message to ensure it comes from a trusted source
      //   if (event.origin !== 'https://trusted-iframe-source.com') {
      //     return; // Ignore messages from untrusted origins
      //   }
      if (event.data.type !== 'set-stem-player-data') {
        return;
      }
      if (event.data.colors) {
        setColors(event.data.colors);
        for (const playlistState of playlistStates) {
          if (!playlistState.playlist) {
            continue;
          }
          playlistState.playlist.colors = event.data.colors;
          if (currentVersion == playlistState.versionId) {
            playlistState.playlist.drawRequest();
          }
        }
        await new Promise((resolve, reject) => {
          setTimeout(resolve, 0);
        });
      }
      if (!event.data.stems) {
        return;
      }
      // Access the data sent from the iframe
      const stems: File[] = Array.from(event.data.stems);
      const versionId = event.data.songId || hash(stems);
      console.log(currentVersion, versionId, currentVersion === versionId);
      if (currentVersion === versionId) {
        return;
      }
      const playListState = init(versionId);
      let stemsIds: string[] = [];
      if (playListState?.soloMutedStems) {
        stemsIds = Array.from(playListState?.soloMutedStems.keys());
      } else {
        stemsIds =
          stems?.map(
            _ =>
              'stem_' +
              generateId() +
              '_' +
              new Date()
                .toISOString()
                .replace(/[-T:.]/g, '_')
                .slice(0, -1)
          ) ?? [];
      }
      createNewSong(
        null,
        stems?.map((stem, i) => ({
          id: stemsIds[i],
          songParentId: generateId(),
          mime: stem.type,
          name: stem.name,
          createdAt: new Date().toString(),
          updatedAt: new Date().toString(),
          uploadedBy: '',
          size: stem.size,
          order: i + 1,
          url: createObjectUrl(stem)
        })) ?? [],
        [{ id: versionId, versionNumber: 1, songName: 'the song', createdAt: 'ertf' }],
        versionId,
        '',
        '',
        new Date().toString(),
        new Date().toString(),
        '',
        0,
        []
      );
    };

    // Set up the event listener for messages
    window.addEventListener('message', handleIframeMessage);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, [currentVersion, playlist, colors]);
  const playListStopPlayingHandler = () => {
    setIsStemsPlaying(false);
  };
  const handleButtonClick = () => {
    setIsStemsPlaying(isPlaying => {
      if (isPlaying) {
        playlistEventEmitterRef.current?.emit('pause');
      } else {
        playlistEventEmitterRef.current?.emit('play');
      }
      return !isPlaying;
    });
  };
  useEffect(() => {
    if (playlist) {
      const zoomIndex = playlist.zoomLevels.length - zoomLevel - 1;
      if (playlist.zoomIndex !== zoomIndex) {
        playlist.setZoomIndex(zoomIndex);
        playlist.setZoom(playlist.zoomLevels[zoomIndex]);
        playlist.drawRequest();
      }
    }
  }, [zoomLevel, playlist]);
  const playlistEventEmitterRef = useRef(playlistEventEmitter);

  useEffect(() => {
    playlistEventEmitterRef.current = playlistEventEmitter;
  }, [playlistEventEmitter]);

  useEffect(() => {
    const handleOrientationChange = () => {
      if (isStemsPlaying) {
        playlistEventEmitterRef.current?.emit('pause');
        setTimeout(() => {
          playlistEventEmitterRef.current?.emit('play');
        }, 2000);
      }
    };

    window.addEventListener('orientationchange', handleOrientationChange);

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, [isStemsPlaying]);
  useEffect(() => {
    document.body.style.backgroundColor = colors?.background ?? 'black';
  }, [colors]);
  return (
    <Box sx={{ position: 'relative', height: '100%', width: '100%' }}>
      {!isNewSong && currentVersion && !!stems.length ? (
        <>
          <Stack
            direction="row"
            justifyContent={'flex-end'}
            p={1}
            sx={{
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              background: 'linear-gradient(180deg, rgba(0, 142, 243, 0.3) 0%, rgba(0, 142, 243, 0.54) 0.02%, rgba(18, 18, 18, 0) 110%)'
            }}
          >
            <Grid width={'auto'} container flexGrow={0} justifyContent="flex-start" alignItems={'center'}>
              <Grid item>
                <IconButton size="small" onClick={() => setZoomLevel(zoomLevel - 1)} disabled={zoomLevel <= 0}>
                  <ZoomOutIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <Slider
                  min={0}
                  max={12}
                  size="small"
                  aria-label="Zoom"
                  value={zoomLevel}
                  onChange={handleZoomSliderChange}
                  sx={{
                    width: 70,
                    '& .MuiSlider-thumb': {
                      backgroundColor: 'white'
                    },
                    '& .MuiSlider-rail': {
                      backgroundColor: '#131313'
                    }
                  }}
                />
              </Grid>

              <Grid item>
                <IconButton size="small" onClick={() => setZoomLevel(zoomLevel + 1)} disabled={zoomLevel >= 12}>
                  <ZoomInIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Box mx={1}>
              <SongDownload disabled={false} />
            </Box>
          </Stack>

          <Grid container direction="row" flexGrow={1} height={1}>
            <Grid item xs={isMobile ? 5 : 3}>
              <Stack
                direction="row"
                pl={3}
                pr={2}
                justifyContent="space-between"
                sx={{
                  position: 'sticky',
                  top: '0',
                  background: '#151515',
                  borderRight: '1px solid #494949',
                  borderBottom: '1px solid #494949',
                  zIndex: '999',

                  paddingTop: '17px',
                  paddingBottom: '16px'
                }}
              >
                <Tooltip title={'Play stems'}>
                  <Button
                    size="small"
                    sx={{
                      minWidth: 'unset',
                      ...(isMobile ? { width: '40px', height: '40px' } : { width: '28px', height: '28px' }),
                      borderRadius: '50%'
                    }}
                    disabled={!stems?.length}
                    variant="contained"
                    color="info"
                    onClick={handleButtonClick}
                  >
                    {isStemsPlaying ? <PauseIcon fontSize="small" /> : <PlayArrowIcon fontSize="small" sx={{ margin: 0 }} />}
                  </Button>
                </Tooltip>
                {stemsCountForLoading && !isStemsRendered && (
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    fontWeight={400}
                    fontSize={14}
                    color="text.primary"
                    fontFamily={'DM Sans, sans-serif'}
                  >
                    <CircularProgress size={14} sx={{ mr: 1 }} color="inherit" />
                    Loading {loadedStemsCount}/{stemsCountForLoading}
                  </Stack>
                )}
              </Stack>
              <DragDropContext onDragStart={() => setIsReorderingStems(true)} onDragEnd={handleStemsReorder}>
                <Droppable droppableId="droppable" direction="vertical">
                  {({ innerRef, droppableProps, placeholder }) => (
                    <Box ref={innerRef} {...droppableProps}>
                      {stems?.map((stem, i) => (
                        <Draggable isDragDisabled={true} draggableId={stem.id} index={i} key={stem.id}>
                          {({ innerRef, draggableProps, dragHandleProps }) => (
                            <StemItem
                              isReordering={isReorderingStems}
                              innerRef={innerRef}
                              draggableProps={draggableProps}
                              dragHandleProps={dragHandleProps}
                              key={stem.id}
                              stem={stem}
                              isSolo={soloMutedStems?.get(stem.id)?.solo}
                              isMuted={soloMutedStems?.get(stem.id)?.muted}
                              canEdit={false}
                              isOwner={false}
                              canDownload={true}
                              playlistEventEmitter={playlistEventEmitter}
                              playlist={playlist}
                              setSoloMuted={setSoloMutedStems}
                              soloMutedStems={soloMutedStems}
                              index={i}
                              setIsBackdrop={() => {}}
                              isStemsPlaying={false}
                            />
                          )}
                        </Draggable>
                      ))}
                      {placeholder}
                    </Box>
                  )}
                </Droppable>
              </DragDropContext>
            </Grid>
            <Grid item xs={isMobile ? 7 : 9} position="relative">
              <Playlist
                colors={colors}
                soloMutedStems={soloMutedStems}
                versionId={currentVersion!}
                stems={stems.map(stem => ({ name: stem.name, src: stem.url }))}
                onStopPlaying={playListStopPlayingHandler}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          demo widget
        </Box>
      )}
    </Box>
  );
}
