import { useAuth0 } from '@auth0/auth0-react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import JSZip from 'jszip';
import mime from 'mime';
import { useEffect, useRef, useState } from 'react';
import { NotificationType } from '../@types/notifications';
import notificationService from '../services/notificationService';
import { useSongSelector } from '../store/selectors/song.selector';
import { useUploadFilesSelector } from '../store/selectors/uploadFiles.selector';
import { useBeforeUnload } from '../utils/useBeforeUnload';
import { CancelDownloadingDialog } from './CancelDownloadDialog';
import { DialogCloseButton } from './DialogCloseButton';
// @ts-ignore

type DownloadSongFilesDialogProps = {
  songName: string;
  songId: string;
  onClose: () => void;
  open: boolean;
};

export default function DownloadSongFilesDialogSidebar({ songId, songName, onClose, open }: DownloadSongFilesDialogProps) {
  useBeforeUnload();

  const { user } = useAuth0();

  const { getSongWithStemsSidebar } = useSongSelector();
  const { uploadingSongs } = useUploadFilesSelector();

  const [downloadStems, setDownloadStems] = useState(false);
  const [downloadSong, setDownloadSong] = useState(false);
  const [downloadWidth, setDownloadWidth] = useState<number>(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const abortControllerRef = useRef<AbortController | null>(null);
  const [triggerDownload, setTriggerDownload] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (triggerDownload) {
      handleDownload(downloadWidth > 0);
    }
  }, [triggerDownload]);

  useEffect(() => {
    const currentUploadingSong = uploadingSongs.find(uploadSong => uploadSong.songId === songId);

    setIsUploading(!!currentUploadingSong);
  }, [uploadingSongs]);

  // useEffect(() => {
  //   if (triggerDownload) {
  //     if ((!song || isSongRendered) && isStemsRendered) {
  //       handleDownload(downloadWidth > 0);
  //     } else {
  //       setDownloadWidth(() => {
  //         if (stemsCountForLoading === 0) {
  //           return 33;
  //         } else {
  //           return loadedStemsCount === 0 ? 3 : loadedStemsCount * (33 / stemsCountForLoading);
  //         }
  //       });
  //     }
  //   }
  // }, [playlistState, triggerDownload]);

  const handleDownload = async (fakeLoading = false) => {
    const files = await getSongWithStemsSidebar(songId);
    const downloadStems = files.stems.length > 0;
    const downloadSong = !!files.song;
    setIsDownloading(true);
    abortControllerRef.current = new AbortController();
    const { signal } = abortControllerRef.current;

    if (files.stems.length === 1) {
      try {
        await new Promise((resolve, reject) => {
          setDownloadWidth(5);
          setIsDownloading(true);

          setTimeout(() => {
            if (signal.aborted) {
              reject(new Error('Download cancelled'));
            } else {
              resolve(null);
            }
          }, 0);
        });
      } catch (error) {
        console.log('Download cancelled');
        setIsDownloading(false);
        onClose();
      }
    }

    try {
      const zip = new JSZip();
      const filesForDownload: { name: string; url?: string; blob?: Blob }[] = [];

      let totalStems: number;

      let percentagePerFile: number;
      let progress: number;

      if (fakeLoading) {
        totalStems = files.stems.length;
        percentagePerFile = 33 / files.stems.length;
        progress = 33;
      } else {
        totalStems = downloadStems ? files.stems.length : 0;
        percentagePerFile = files.stems.length === 1 ? 45 / totalStems : 50 / totalStems;
        progress = files.stems.length === 1 ? 5 : 0;
      }

      if (downloadStems) {
        let downloadPromises: Promise<any>[] = [];
        for (let stem of files.stems) {
          downloadPromises.push(
            new Promise(async (resolve, reject) => {
              const response = await fetch(stem.url);
              const blob = await response.blob();

              let mimeExtension = mime.getExtension(stem.mime) || '';

              if (stem.mime === 'audio/flac') mimeExtension = 'flac';
              if (mimeExtension === 'mpga') mimeExtension = 'mp3';
              if (mimeExtension === 'oga') mimeExtension = 'ogg';
              if (stem.mime === 'audio/aiff') mimeExtension = 'aiff';

              filesForDownload.push({
                name: `${stem.name.replace(/\.[^/.]+$/, '')}.${mimeExtension}`,
                blob
              });

              progress += percentagePerFile;
              setDownloadWidth(progress);
              setTimeout(() => {
                if (signal.aborted) {
                  reject(new Error('Download cancelled'));
                } else {
                  resolve(null);
                }
              }, 0);
            })
          );
        }
        await Promise.all(downloadPromises);
      }

      if (downloadSong) {
        let mimeExtension = mime.getExtension(files.song.mime) || '';

        if (files.song.mime === 'audio/flac') mimeExtension = 'flac';
        if (mimeExtension === 'mpga') mimeExtension = 'mp3';
        if (mimeExtension === 'oga') mimeExtension = 'ogg';

        filesForDownload.push({
          name: files.song.name.replace(/\.[^/.]+$/, '')! + (mimeExtension?.startsWith('.') ? mimeExtension : `.${mimeExtension}`),
          url: files.song.url
        });
      }

      const downloadingBlobs = filesForDownload.map(async file => {
        let blobData: Blob;

        if (!file.url) {
          blobData = file.blob!;
        } else {
          const response = await fetch(file.url, { signal });
          blobData = await response.blob();
        }

        zip.file(file.name, blobData);
      });

      await Promise.all(downloadingBlobs);

      await zip
        .generateAsync({ type: 'blob' }, data => {
          setDownloadWidth(fakeLoading ? data.percent * 0.34 + 66 : data.percent / 2 + 50);
        })
        .then(function (content: any) {
          if (!signal.aborted) {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(content);
            link.download = songName + '.zip';
            link.click();
          }
        });
      !!user && notificationService.createNotification(NotificationType.DownloadSong, files.songParentId!);
      setIsDownloading(false);
      onClose();
    } catch (error) {
      // @ts-ignore
      if (error.name === 'AbortError') {
        console.log('Download cancelled');
      } else {
        console.error('Download failed', error);
      }
      setIsDownloading(false);
    }
  };

  const handleCancel = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    setIsDownloading(false);
    setDownloadWidth(0);
    onClose();
  };

  return (
    <Dialog
      fullWidth
      maxWidth={'xs'}
      open={open}
      onClose={() => {
        if (triggerDownload) {
          setShowCancelModal(true);
        } else {
          onClose();
        }
      }}
      PaperProps={{ sx: { background: 'rgba(21, 21, 21, 1)', width: '100%', margin: 2 } }}
    >
      <DialogCloseButton
        onClose={() => {
          if (triggerDownload) {
            setShowCancelModal(true);
          } else {
            onClose();
          }
        }}
      />
      <DialogTitle
        sx={{
          width: '90%',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          fontSize: '16px',
          lineHeight: '24px',
          fontWeight: '500'
        }}
      >
        {triggerDownload ? 'Downloading' : 'Download'} song "{songName}"
      </DialogTitle>
      <DialogContent
        sx={{
          position: 'relative'
        }}
        dividers
      >
        <Box
          sx={{
            width: `${downloadWidth}%`,
            height: '100%',
            background: 'linear-gradient(266deg, #008EF3 8.39%, rgba(22, 227, 245, 0.85) 88.47%)',
            top: 0,
            left: 0,
            position: 'absolute',
            zIndex: 10
          }}
        ></Box>
        <Stack flexDirection="column">
          {downloadSong && (
            <Stack flexDirection="row" alignItems="center">
              <Checkbox
                checked={downloadSong}
                onChange={() => setDownloadSong(prev => !prev)}
                color="info"
                checkedIcon={<CheckBoxIcon sx={{ color: '#008EF3 !important' }} />}
              />
              <Typography
                sx={{
                  fontSize: '16px',
                  lineHeight: '24px',
                  fontWeight: '500'
                }}
              >
                Song
              </Typography>
            </Stack>
          )}
          {downloadStems && (
            <Stack flexDirection="row" alignItems="center">
              <Checkbox
                checked={downloadStems}
                onChange={() => setDownloadStems(prev => !prev)}
                color="info"
                checkedIcon={<CheckBoxIcon sx={{ color: '#008EF3 !important' }} />}
              />
              <Typography
                sx={{
                  fontSize: '16px',
                  lineHeight: '24px',
                  fontWeight: '500'
                }}
              >
                Stems
              </Typography>
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} size="small" color="secondary" variant="contained">
          Cancel
        </Button>
        <Button
          onClick={() => setTriggerDownload(true)}
          disabled={isDownloading || isUploading}
          size="small"
          color="info"
          variant="contained"
        >
          Download files
        </Button>
      </DialogActions>
      {showCancelModal && (
        <CancelDownloadingDialog open={showCancelModal} onCancel={handleCancel} onClose={() => setShowCancelModal(false)} />
      )}
    </Dialog>
  );
}
