import { useAuth0 } from '@auth0/auth0-react';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import DownloadIcon from '@mui/icons-material/Download';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import LinkIcon from '@mui/icons-material/Link';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FileType } from '../../../@types/files';
import { SidebarFolder } from '../../../@types/folders';
import { NotificationType } from '../../../@types/notifications';
import { ReactComponent as DeleteIconWidget } from '../../../assets/icons/delete.svg';
import { ReactComponent as CustomDownloadIcon } from '../../../assets/icons/downloadIcon.svg';
import { ReactComponent as RenameIcon } from '../../../assets/icons/renameIcon.svg';
import { ReactComponent as SharingIcon } from '../../../assets/icons/sharingIcon.svg';
import SharingFolderDialog from '../../../components/SharingFolderDialog';
import folderService from '../../../services/folderService';
import notificationService from '../../../services/notificationService';
import songsService from '../../../services/songsService';
import trackEvent from '../../../services/trackService';
import { useFoldersSelector } from '../../../store/selectors/folders.selector';
import { useSongSelector } from '../../../store/selectors/song.selector';
import { useUploadFilesSelector } from '../../../store/selectors/uploadFiles.selector';
import { useSidebarStore } from '../../../store/sidebar.store';
import { useUserStore } from '../../../store/user.store';
import { formatDateMDTime, formatDateMDY } from '../../../utils/date';
import { calculateFolderSize, checkFileType, FolderNode, getFile, readDirectory } from '../../../utils/fileUtils';
import { generateId } from '../../../utils/generateId';
import { processUnZipFile } from '../../../utils/useUnzipWorker';
import DeleteFolderDialog from '../Dialogs/DeleteFolderDialog';
import FolderDropDownItemsList from './FolderDropDownItemsList';

type FolderDropDownProps = {
  folder: SidebarFolder;
  setMovingSongToFolder: React.Dispatch<React.SetStateAction<null | { folderName: string; songName: string }>>;
  highlightedSongId: string;
  setHighlightedSongId: (songId: string) => void;
  setIsBackdrop: (boolean: boolean) => void;
  isSharingFolder?: boolean;
  level?: number;
  isOrganisation?: boolean;
};

export default function FolderDropDown({
  folder,
  setMovingSongToFolder,
  setHighlightedSongId,
  highlightedSongId,
  setIsBackdrop,
  isSharingFolder = false,
  level = 0,
  isOrganisation = false
}: FolderDropDownProps) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [folderIdForDelete, setFolderIdForDelete] = useState<string>('');
  const [folderIdForRename, setFolderIdForRename] = useState<string>('');
  const renameInputRef = useRef<null | HTMLInputElement>(null);
  const [isSharingFolderDialogOpen, setIsSharingFolderDialogOpen] = useState<boolean>(false);
  const screenWidth = window.innerWidth;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { user } = useAuth0();
  const userInfo = useUserStore(state => state.user);
  let timer: string | number | NodeJS.Timeout | undefined;
  const maxSizeStorage = useUserStore(state => state.maxSizeStorage);
  const usedStorage = useUserStore(state => state.usedStorage);
  const [showWidgets, setShowWidgets] = useState(false);
  const sidebarWidth = useSidebarStore(state => state.sidebarWidth);

  const {
    renameFolder,
    deleteFolder,
    moveFolderSong,
    isRenameLoading,
    sharedFolderUsers,
    toggleFolder,
    moveFolder,
    createFolder,
    defaultFolder
  } = useFoldersSelector();

  const { createNewSong } = useSongSelector();
  const { setUploadData, createParentSong, lastQueue, currentPreparedSharedUsers } = useUploadFilesSelector();

  useEffect(() => {
    renameInputRef.current?.focus();
  }, [folderIdForRename]);

  const handleOpenFolderMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isMobile) return;

    if (
      (isSharingFolder && folder.accessType !== 'EDITOR' && folder.accessType !== 'CO_OWNER') ||
      folder.id === 'organisation' ||
      folder.id === 'default-folder' ||
      (isOrganisation && level === 1)
    )
      return;

    e.preventDefault();
    e.stopPropagation();

    if (isMobile) {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleCloseFolderMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenFolderDeleteDialog = (folderId: string) => {
    handleCloseFolderMenu();
    setFolderIdForDelete(folderId);
  };

  const handleCloseFolderDeleteDialog = () => {
    setFolderIdForDelete('');
  };

  const handleRename = (folderId: string) => {
    handleCloseFolderMenu();
    setFolderIdForRename(folderId);
  };

  const handleShareFolder = (folderId: string) => {
    handleCloseFolderMenu();
    setIsSharingFolderDialogOpen(true);
  };

  const handleRenameOnBlur = () => {
    setFolderIdForRename('');
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      renameFolder(folder.id, renameInputRef.current?.value.trim() || folder.name, user?.name || user?.email).then(() => {
        enqueueSnackbar('Folder renamed successfully', { variant: 'success' });
      });

      handleRenameOnBlur();
    }
  };
  const triggerNavigating = async (
    song?: File,
    stems?: File[],
    fId?: string,
    toggleNavigate?: boolean,
    songName?: string,
    fileType?: FileType
  ) => {
    const parentSongId = generateId();
    const folderId = fId ?? defaultFolder?.id ?? '';

    const nameOfSong = song?.name ?? songName ?? stems?.at(0)?.name ?? 'DEMO_' + formatDateMDY(new Date());
    const parentSong = await createParentSong(folderId, parentSongId, nameOfSong, fileType ?? FileType.Audio);
    const firstVersion = await songsService.addSongVersion(parentSongId, nameOfSong, '');

    firstVersion.songName = nameOfSong;

    const stemsIds =
      stems?.map(
        _ =>
          'stem_' +
          generateId() +
          '_' +
          new Date()
            .toISOString()
            .replace(/[-T:.]/g, '_')
            .slice(0, -1) +
          (fileType === FileType.Audio ? '_audio' : '_other')
      ) ?? [];

    if (toggleNavigate) {
      createNewSong(
        song
          ? {
              id:
                'song_' +
                generateId() +
                '_' +
                new Date()
                  .toISOString()
                  .replace(/[-T:.]/g, '_')
                  .slice(0, -1),
              mime: song.type,
              name: song.name,
              createdAt: new Date().toString(),
              updatedAt: new Date().toString(),
              uploadedBy: user?.name,
              size: song.size,
              order: 0,
              url: URL.createObjectURL(song)
            }
          : null,
        stems?.map((stem, i) => ({
          id: stemsIds[i],
          songParentId: parentSongId,
          mime: stem.type,
          name: stem.name,
          createdAt: new Date().toString(),
          updatedAt: new Date().toString(),
          uploadedBy: user?.name,
          size: stem.size,
          order: i + 1,
          url: URL.createObjectURL(stem)
        })) ?? [],
        [firstVersion],
        firstVersion.id,
        parentSongId,
        '',
        new Date().toString(),
        new Date().toString(),
        user?.name,
        0,
        currentPreparedSharedUsers
      );
    }

    (song || stems) &&
      setUploadData(
        song ? { file: song, progress: 0, estimatedTime: 0, versionId: firstVersion.id! } : null,
        stems?.map((stem, i) => ({
          file: stem,
          progress: 0,
          estimatedTime: 0,
          stemId: stemsIds[i],
          queueId: lastQueue + 1,
          versionId: firstVersion.id!,
          folderName: songName,
          order: i + 1
        })) ?? [],
        parentSongId,
        folderId,
        fileType ?? FileType.Audio,
        true
      );

    notificationService.createNotification(NotificationType.UploadSong, parentSongId, folderId);

    if (toggleNavigate) {
      navigate(`/dashboard`);
      timer = setTimeout(() => navigate(`/dashboard/${folderId}/${parentSongId}${fileType === FileType.Other ? '/file' : ''}`), 20);
    }
  };

  const traverseAndCreateFolders = async (folders: FolderNode[], parentFolderId?: string, nav?: boolean): Promise<any> => {
    let navigate = nav === undefined ? true : nav;

    for (const folder of folders) {
      const otherFiles: File[] = [];
      const audioFiles: File[] = [];
      for (const file of folder.files) {
        if ((await checkFileType(file)) === FileType.Audio) {
          audioFiles.push(file);
        } else if ((await checkFileType(file)) === FileType.Other) {
          otherFiles.push(file);
        }
      }
      let withoutFolder = !folder.subFolders.length && !!folder.files.length && !otherFiles.length;
      let createdFolder: SidebarFolder | undefined = undefined;

      if (folder.id !== 'no_folder' && !withoutFolder) {
        createdFolder = await createFolder(folder.name, parentFolderId, user?.name || user?.email);
      }

      if (folder.files.length) {
        if (audioFiles.length) {
          await triggerNavigating(
            undefined,
            audioFiles,
            createdFolder?.id! ?? parentFolderId,
            navigate,
            (withoutFolder || !folder.subFolders.length) && folder.id !== 'no_folder' ? folder.name : undefined,
            FileType.Audio
          );

          navigate = false;
        }
        if (otherFiles.length) {
          for (const other of otherFiles) {
            await triggerNavigating(undefined, [other], createdFolder?.id! ?? parentFolderId, navigate, undefined, FileType.Other);
            navigate = false;
          }
        }
      }

      await traverseAndCreateFolders(folder.subFolders, createdFolder?.id!, navigate);
    }
  };

  const checkEntries = async (entries: FileSystemEntry[]): Promise<boolean> => {
    for (const entry of entries) {
      if (entry.isDirectory) {
        return false;
      }

      const file = await getFile(entry as FileSystemFileEntry);
      const mimeType = file.type || 'application/octet-stream';

      if (!mimeType.startsWith('audio/') && !file.name.endsWith('.aiff')) {
        return false;
      }
    }

    return true;
  };

  function sortFolderNode(folderNode: FolderNode): FolderNode {
    folderNode.files.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));

    folderNode.subFolders.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));

    folderNode.subFolders.forEach(sortFolderNode);

    return folderNode;
  }

  const uploadStems = async (fileList?: FileList, fileSystemEntry?: FileSystemEntry[]) => {
    const folderTree: FolderNode[] = [];
    setIsBackdrop(true);

    if (fileSystemEntry?.length) {
      const filePromises = fileSystemEntry.map(async entry => {
        if (entry.isDirectory) {
          const folderNode = await readDirectory(entry as FileSystemDirectoryEntry);

          folderTree.push(folderNode);
        } else {
          const file = await getFile(entry as FileSystemFileEntry);
          const mimeType = file.type || 'application/octet-stream';

          if (mimeType === 'application/zip') {
            const stems = await processUnZipFile(file);

            folderTree.push(...stems);
          } else {
            if (!folderTree.length) {
              folderTree.push({ id: 'no_folder', name: 'no folder', files: [], subFolders: [] });
            }
            folderTree?.at(0)?.files.push(file);
          }
        }
      });

      await Promise.all(filePromises);
    } else if (fileList?.length) {
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];

        if (file.type === 'application/zip') {
          const stems = await processUnZipFile(file);

          folderTree.push(...stems);
        } else {
          if (!folderTree.length) {
            folderTree.push({ id: 'no_folder', name: 'no folder', files: [], subFolders: [] });
          }

          folderTree?.at(0)?.files.push(file);
        }
      }
    }

    setIsBackdrop(false);

    const size = calculateFolderSize(folderTree);

    if (!(size + usedStorage > maxSizeStorage)) {
      trackEvent('batch-upload', { type: 'new-song' });

      const sortedFolderTree = folderTree.map(folder => sortFolderNode(folder));

      await traverseAndCreateFolders(sortedFolderTree, folder.id);
    } else {
      enqueueSnackbar("You haven't enough space. Update your plan.", { variant: 'error' });

      navigate('/plans');
    }
  };

  const handleDropMusicToFolder = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (isSharingFolder && folder.accessType !== 'EDITOR') return;

    const items = e.dataTransfer.items;
    const fileSystemEntries: FileSystemEntry[] = [];

    Array.from(items).forEach(file => {
      const entry = file.webkitGetAsEntry();

      if (entry) {
        fileSystemEntries.push(entry);
      }
    });

    if (fileSystemEntries.length) {
      uploadStems(undefined, fileSystemEntries);
      return;
    }

    const draggedData = JSON.parse(e.dataTransfer.getData('text/plain'));

    if (draggedData.folderId === folder.id) return;

    if (!!draggedData.folderName) {
      await moveFolder(draggedData.folderId, folder.id, user?.name || user?.email);
    } else if (!!draggedData.songName) {
      setMovingSongToFolder({ folderName: folder.name, songName: draggedData.songName });

      await moveFolderSong(
        { songId: draggedData.songId, currentFolderId: draggedData.folderId, newFolderId: folder.id },
        user?.name || user?.email
      );

      enqueueSnackbar('Song moved successfully', { variant: 'success' });
      setMovingSongToFolder(null);
    }
  };

  const renderUploadInfo = () => {
    if (folder.updatedAt && folder.updatedByName) {
      return `Updated ${formatDateMDTime(folder.updatedAt, true)} by ${folder.updatedByName}`;
    } else if (folder.createdAt && folder.createdByName) {
      return `Uploaded ${formatDateMDTime(folder.createdAt, true)} by ${folder.createdByName}`;
    } else if (folder.createdByName) {
      return `Uploaded by ${folder.createdByName}`;
    }
    return '';
  };

  const getWidgetsWidth = () => {
    if (showWidgets) {
      if (!folder.accessType) return 110;

      if (folder.accessType === 'EDITOR') return 110;

      if (folder.accessType === 'CO_OWNER') return 25;

      return 0;
    }

    return 0;
  };

  return (
    <>
      {isSharingFolderDialogOpen && (
        <SharingFolderDialog
          folderId={folder.id}
          folderName={folder.name}
          isOwner={true}
          sharedFolderUsers={sharedFolderUsers}
          onClose={() => setIsSharingFolderDialogOpen(false)}
          open={isSharingFolderDialogOpen}
          ownerEmail={folder.user?.email}
        />
      )}
      <DeleteFolderDialog
        open={Boolean(folderIdForDelete)}
        folder={folder}
        onClose={handleCloseFolderDeleteDialog}
        onConfirm={() => {
          deleteFolder(folder.id).then(() => {
            enqueueSnackbar('Folder deleted successfully', { variant: 'success' });
          });
          handleCloseFolderDeleteDialog();
          navigate('/dashboard');
        }}
      />
      <Accordion
        expanded={folder.isOpen}
        sx={{
          boxShadow: 'none',
          '& .MuiAccordionSummary-root.Mui-expanded': {
            minHeight: '56px !important'
          },
          ...(isMobile
            ? {}
            : {
                '&.MuiPaper-root': {
                  maxWidth: `calc(${sidebarWidth}px - 32px - ${level * 16}px) !important`
                }
              })
        }}
        onChange={() => {
          toggleFolder(folder.id);
        }}
      >
        <AccordionSummary
          draggable={!isSharingFolder && folder.id !== 'shared_folder' && !isOrganisation}
          onMouseEnter={() => {
            if (isMobile) return;

            if (
              (isSharingFolder && folder.accessType !== 'EDITOR' && folder.accessType !== 'CO_OWNER') ||
              folder.id === 'organisation' ||
              folder.id === 'default-folder' ||
              (isOrganisation && level === 1)
            )
              return;

            setShowWidgets(true);
          }}
          onMouseLeave={() => {
            if (isMobile) return;

            if (
              (isSharingFolder && folder.accessType !== 'EDITOR' && folder.accessType !== 'CO_OWNER') ||
              folder.id === 'organisation' ||
              folder.id === 'default-folder' ||
              (isOrganisation && level === 1)
            )
              return;

            setShowWidgets(false);
          }}
          onDragStart={e => {
            if (isSharingFolder || isOrganisation) return;

            e.dataTransfer.setData('text/plain', JSON.stringify({ folderId: folder.id, folderName: folder.name }));
          }}
          onDrop={handleDropMusicToFolder}
          onDragOver={e => e.preventDefault()}
          sx={{
            position: 'relative',
            backgroundColor: '#151515',
            p: 0,
            '& .MuiAccordionSummary-content': { m: '0 !important' },
            '& .MuiAccordionSummary-content.isExpanded': { m: '0 !important' },
            margin: 0
          }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${folder.id}-content`}
          id={`${folder.id}-header`}
        >
          {showWidgets && !folderIdForRename && (
            <Stack
              position={'absolute'}
              zIndex={1}
              direction={'row'}
              top={'50%'}
              right={`27px`}
              gap={`3px`}
              sx={{ transform: 'translateY(-50%)' }}
              onClick={e => {
                e.stopPropagation();
              }}
            >
              {(folder.accessType === 'EDITOR' || !folder.accessType) && (
                <IconButton
                  onClick={e => {
                    e.stopPropagation();
                    handleShareFolder(folder.id);
                  }}
                  sx={{ minWidth: '25px', maxWidth: '25px', height: '25px', p: 0 }}
                >
                  <SharingIcon />
                </IconButton>
              )}
              {(folder.accessType === 'EDITOR' || folder.accessType === 'CO_OWNER' || !folder.accessType) && (
                <IconButton
                  onClick={async e => {
                    e.stopPropagation();

                    const handleDownload = async () => {
                      setIsBackdrop(true);

                      try {
                        const response = await folderService.downloadFolder(folder.id);

                        const url = window.URL.createObjectURL(new Blob([response.data]));

                        const link = document.createElement('a');
                        link.href = url;

                        link.setAttribute('download', `${folder.name || `folder_${folder.id}`}.zip`);

                        document.body.appendChild(link);
                        link.click();

                        setIsBackdrop(false);

                        document.body.removeChild(link);
                      } catch (error) {
                        setIsBackdrop(false);
                        console.error('Error downloading the folder:', error);
                      }
                    };

                    setAnchorEl(null);

                    await handleDownload();
                  }}
                  sx={{ minWidth: '25px', maxWidth: '25px', height: '25px', p: 0 }}
                >
                  <CustomDownloadIcon />
                </IconButton>
              )}
              {(folder.accessType === 'EDITOR' || !folder.accessType) && (
                <IconButton
                  onClick={e => {
                    e.stopPropagation();
                    handleRename(folder.id);
                  }}
                  sx={{ minWidth: '25px', maxWidth: '25px', height: '25px', p: 0 }}
                >
                  <RenameIcon />
                </IconButton>
              )}

              {(folder.accessType === 'EDITOR' || !folder.accessType) && (
                <IconButton
                  onClick={e => {
                    e.stopPropagation();
                    handleOpenFolderDeleteDialog(folder.id);
                  }}
                  sx={{ minWidth: '25px', maxWidth: '25px', height: '25px', p: 0 }}
                >
                  <DeleteIconWidget />
                </IconButton>
              )}
            </Stack>
          )}
          <Stack direction="row" spacing={2} alignItems="center" maxWidth={'100% !important'}>
            <Box
              width="48px"
              height="48px"
              position="relative"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: folder.isOpen ? 'linear-gradient(266deg, #008EF3 8.39%, rgba(22, 225, 245, 0.85) 88.47%)' : '#4B4B4B66',
                borderRadius: 1,
                margin: 0,
                flexShrink: 0
              }}
            >
              {folder.id !== 'organisation' && folder.id !== 'shared_folder' && (
                <FolderOutlinedIcon fontSize="medium" sx={{ position: 'absolute' }} />
              )}
              {isSharingFolder && folder.id === 'shared_folder' && (
                <PersonAddOutlinedIcon fontSize="medium" sx={{ position: 'absolute' }} />
              )}
              {folder.id === 'organisation' && <BusinessRoundedIcon fontSize="medium" sx={{ position: 'absolute' }} />}
            </Box>
            {folderIdForRename ? (
              <TextField
                size="small"
                defaultValue={folder.name}
                inputRef={renameInputRef}
                onKeyDown={handleKeyDown}
                onBlur={handleRenameOnBlur}
                sx={{ fontSize: '14px' }}
              />
            ) : (
              <Box onClick={handleOpenFolderMenu}>
                <Tooltip title={folder.name.length > 16 ? folder.name : ''}>
                  <Typography
                    sx={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      maxWidth: isMobile
                        ? '190px'
                        : `calc(${sidebarWidth}px - 72px - 32px - 20px - ${getWidgetsWidth()}px  - ${level * 16}px)`,
                      fontSize: '14px'
                    }}
                  >
                    {folder.id === 'organisation' ? userInfo?.organizationName : folder.name}{' '}
                    {isRenameLoading === folder.id && 'Loading....'}
                  </Typography>
                </Tooltip>

                <Tooltip title={renderUploadInfo()}>
                  <Typography
                    fontSize={12}
                    color="rgba(255, 255, 255, 0.7)"
                    sx={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      maxWidth: isMobile
                        ? `calc(170px - ${level * (isSharingFolder ? 24 : 16)}px)`
                        : `calc(${sidebarWidth}px - 72px - 32px - 20px - ${getWidgetsWidth()}px  - ${level * 16}px)`
                    }}
                  >
                    {renderUploadInfo()}
                  </Typography>
                </Tooltip>
              </Box>
            )}
          </Stack>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: '#151515',
            '&.MuiAccordionDetails-root': {
              pr: 0
            }
          }}
        >
          {!!folder.children.length && (
            <Grid container direction={'column'} mb={1} spacing={1}>
              {folder.children.map(child => {
                return (
                  <Grid
                    item
                    key={child.id + child.name}
                    sx={{
                      maxWidth: !isMobile ? `calc(${sidebarWidth}px - ${(level + 1) * 16}px) !important` : 'none'
                    }}
                  >
                    <FolderDropDown
                      folder={child}
                      setMovingSongToFolder={setMovingSongToFolder}
                      highlightedSongId={highlightedSongId}
                      setHighlightedSongId={setHighlightedSongId}
                      isSharingFolder={isSharingFolder}
                      level={level + 1}
                      setIsBackdrop={setIsBackdrop}
                      isOrganisation={folder.id === 'organisation' || isOrganisation}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )}
          <FolderDropDownItemsList
            level={level + 1}
            folder={folder}
            setHighlightedSongId={setHighlightedSongId}
            highlightedSongId={highlightedSongId}
            isSharingFiles={isSharingFolder}
          />
        </AccordionDetails>
      </Accordion>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseFolderMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{
          paper: {
            sx: {
              mt: 1.5
            }
          }
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {(folder.accessType === 'EDITOR' || !folder.accessType) && (
          <MenuItem onClick={() => handleShareFolder(folder.id)}>
            <LinkIcon sx={{ mr: 1 }} />
            Share folder
          </MenuItem>
        )}
        {(folder.accessType === 'EDITOR' || !folder.accessType) && (
          <MenuItem onClick={() => handleRename(folder.id)}>
            <DriveFileRenameOutlineIcon fontSize="small" sx={{ mr: 1 }} />
            Rename
          </MenuItem>
        )}
        {(folder.accessType === 'EDITOR' || folder.accessType === 'CO_OWNER' || !folder.accessType) && (
          <MenuItem
            onClick={async () => {
              const handleDownload = async () => {
                setIsBackdrop(true);

                try {
                  const response = await folderService.downloadFolder(folder.id);

                  const url = window.URL.createObjectURL(new Blob([response.data]));

                  const link = document.createElement('a');
                  link.href = url;

                  link.setAttribute('download', `${folder.name || `folder_${folder.id}`}.zip`);

                  document.body.appendChild(link);
                  link.click();

                  setIsBackdrop(false);

                  document.body.removeChild(link);
                } catch (error) {
                  setIsBackdrop(false);
                  console.error('Error downloading the folder:', error);
                }
              };

              setAnchorEl(null);

              await handleDownload();
            }}
          >
            <DownloadIcon fontSize="small" sx={{ mr: 1 }} />
            Download
          </MenuItem>
        )}
        {(folder.accessType === 'EDITOR' || !folder.accessType) && !isOrganisation && (
          <MenuItem sx={{ color: 'red' }} onClick={() => handleOpenFolderDeleteDialog(folder.id)}>
            <DeleteIcon fontSize="small" sx={{ mr: 1, color: 'red' }} />
            Delete
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
