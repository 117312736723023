import { Box, useMediaQuery, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import CommentAvatar from '../../sections/comments/CommentAvatar';
import { usePlaylistStore } from '../../store/players.store';
import StemsPlayCustomTime from './StemsPlayCustomTime';
// @ts-ignore
import WaveformPlaylist from 'waveform-playlist';
import { SoloMutedStems } from '../../@types/songs';
import { useSongSelector } from '../../store/selectors/song.selector';
import { formatTime } from '../../utils/helpers';

import parseID3 from 'id3-parser';
import { useParams } from 'react-router-dom';

interface WavFormatMetadata {
  audioFormat?: string;
  channels?: string;
  sampleRate?: string;
  byteRate?: string;
  blockAlign?: string;
  bitDepth?: string;
}
interface MetadataResult {
  type: 'WAV' | 'MP3' | 'OTHER';
  metadata: Record<string, string> | null;
}

/**
 * Reads a little-endian 32-bit unsigned integer from a Uint8Array.
 */
function readUInt32LE(data: Uint8Array, offset: number): string {
  // DataView approach (recommended for clarity)
  const view = new DataView(data.buffer, data.byteOffset, data.byteLength);
  return view.getUint32(offset, true).toString();
}

/**
 * Reads a little-endian 16-bit unsigned integer from a Uint8Array.
 */
function readUInt16LE(data: Uint8Array, offset: number): string {
  const view = new DataView(data.buffer, data.byteOffset, data.byteLength);
  return view.getUint16(offset, true).toString();
}
async function detectFileTypeAndReadMetadata(arrayBuffer: ArrayBuffer): Promise<MetadataResult> {
  const uint8Array = new Uint8Array(arrayBuffer);

  // Check if it's a WAV file
  const isWav =
    uint8Array[0] === 0x52 && // "R"
    uint8Array[1] === 0x49 && // "I"
    uint8Array[2] === 0x46 && // "F"
    uint8Array[3] === 0x46 && // "F"
    uint8Array[8] === 0x57 && // "W"
    uint8Array[9] === 0x41 && // "A"
    uint8Array[10] === 0x56 && // "V"
    uint8Array[11] === 0x45; // "E"
  if (isWav) {
    const metadata = readWavMetadata(arrayBuffer);
    return { type: 'WAV', metadata };
  }

  // Check if it's an MP3 file
  const isMp3 =
    uint8Array[0] === 0x49 && // "I"
    uint8Array[1] === 0x44 && // "D"
    uint8Array[2] === 0x33; // "3" (ID3 tag header)

  if (isMp3) {
    const metadata = await readMp3Metadata(uint8Array);
    return { type: 'MP3', metadata };
  }
  console.log(isMp3, 'isMp3', isWav, 'isWav');
  // Other file types
  return { type: 'OTHER', metadata: null };
}
interface ListInfoMetadata {
  [key: string]: string;
}

function readAsciiString(data: Uint8Array, start: number, end: number): string {
  let result = '';
  for (let i = start; i < end; i++) {
    result += String.fromCharCode(data[i]);
  }
  return result;
}

function findChunk(arrayBuffer: ArrayBuffer, chunkId: string, startOffset: number = 12): { offset: number; size: number } | null {
  const dataView = new DataView(arrayBuffer);
  const uint8 = new Uint8Array(arrayBuffer);
  let offset = startOffset;

  while (offset + 8 <= arrayBuffer.byteLength) {
    const id = readAsciiString(uint8, offset, offset + 4);
    const size = dataView.getUint32(offset + 4, true); // little-endian
    if (id === chunkId) {
      return { offset, size };
    }
    offset += 8 + size;
  }
  return null;
}

function getFormatChunkMetadata(data: Uint8Array, arrayBuffer: ArrayBuffer): WavFormatMetadata {
  const fmtChunk = findChunk(arrayBuffer, 'fmt ');
  if (!fmtChunk) return {};

  const offset = fmtChunk.offset + 8; // Skip the chunk ID + chunk size fields

  // The standard PCM 'fmt ' chunk layout (for uncompressed WAV):
  //   offset + 0:  wFormatTag      (2 bytes)
  //   offset + 2:  nChannels       (2 bytes)
  //   offset + 4:  nSamplesPerSec  (4 bytes)
  //   offset + 8:  nAvgBytesPerSec (4 bytes)
  //   offset + 12: nBlockAlign     (2 bytes)
  //   offset + 14: wBitsPerSample  (2 bytes)

  const audioFormat = readUInt16LE(data, offset + 0);
  const channels = readUInt16LE(data, offset + 2);
  const sampleRate = readUInt32LE(data, offset + 4);
  const byteRate = readUInt32LE(data, offset + 8);
  const blockAlign = readUInt16LE(data, offset + 12);
  const bitDepth = readUInt16LE(data, offset + 14);

  return {
    audioFormat,
    channels,
    sampleRate,
    byteRate,
    blockAlign,
    bitDepth
  };
}
function readWavMetadata(arrayBuffer: ArrayBuffer): ListInfoMetadata & WavFormatMetadata {
  const info: ListInfoMetadata = {};
  const dataView = new DataView(arrayBuffer);
  const uint8 = new Uint8Array(arrayBuffer);
  let fmtMetadata = getFormatChunkMetadata(uint8, arrayBuffer);
  const listChunk = findChunk(arrayBuffer, 'LIST');
  if (!listChunk) return info;

  const listType = readAsciiString(uint8, listChunk.offset + 8, listChunk.offset + 12);
  if (listType !== 'INFO') return info; // Not an INFO LIST chunk

  let offset = listChunk.offset + 12;
  const listEnd = listChunk.offset + 8 + listChunk.size;

  while (offset + 8 <= listEnd) {
    const subId = readAsciiString(uint8, offset, offset + 4);
    const subSize = dataView.getUint32(offset + 4, true);
    const dataStart = offset + 8;
    const dataEnd = dataStart + subSize;

    if (dataEnd <= listEnd) {
      let value = readAsciiString(uint8, dataStart, dataEnd);
      // Remove trailing NULL chars
      value = value.replace(/\0+$/, '');
      info[subId] = value;
    }

    // Move to next sub-chunk; if subSize is odd, add 1 for padding
    let nextOffset = dataEnd;
    if (subSize % 2 !== 0) {
      nextOffset += 1;
    }
    offset = nextOffset;
  }

  return { ...info, ...fmtMetadata };
}

// Read MP3 metadata using ID3 parsing
async function readMp3Metadata(uint8Array: Uint8Array): Promise<Record<string, string> | null> {
  const id3Metadata = parseID3(uint8Array);
  if (!id3Metadata) return null;
  return id3Metadata;
}

export type PlaylistProps = {
  stems: { name: string; src: string }[];
  onStopPlaying: () => void;
  versionId: string;
  soloMutedStems: Map<string, SoloMutedStems>;
  colors?: any;
  createCommentDialog?: (stemIndex: number, duration: number) => void;
};

function pixelsToSeconds(pixels: number, resolution: number, sampleRate: number) {
  return (pixels * resolution) / sampleRate;
}

function secondsToPixels(seconds: number, resolution: number, sampleRate: number) {
  return Math.ceil((seconds * sampleRate) / resolution);
}

const handlePlayHeadDrag = (renderPlaylist: any) => {
  const tracksElement = document.getElementsByClassName('playlist-tracks')[0];
  if (!tracksElement) return;
  let isPlaying = false;
  let isDragging = false;
  const mousedownHandler = async (e: Event) => {
    isDragging = true;
    const mouseEvent = e as MouseEvent;
    const tracksElementX = tracksElement.getBoundingClientRect()?.left || 0;

    if (renderPlaylist.isPlaying()) {
      isPlaying = true;
      await renderPlaylist.pause();
    }
    mouseEvent.preventDefault();
    const startTime = Math.min(
      pixelsToSeconds(mouseEvent.offsetX, renderPlaylist.samplesPerPixel, renderPlaylist.sampleRate),
      renderPlaylist.duration
    );
    renderPlaylist.ee.emit('select', startTime, startTime, null);
    const mouseupHandler = (e: Event) => {
      e.preventDefault();
      isDragging = false;
      const mouseEvent = e as MouseEvent;
      const endTime = Math.min(
        pixelsToSeconds(
          Math.max(mouseEvent.clientX - (tracksElementX - tracksElement.scrollLeft), 0),
          renderPlaylist.samplesPerPixel,
          renderPlaylist.sampleRate
        ),
        renderPlaylist.duration
      );

      if (isPlaying) {
        setTimeout(async () => {
          if (isPlaying && !isDragging) {
            renderPlaylist.play();
            isPlaying = false;
          }
        }, 100);
      }
      // renderPlaylist.ee.emit('select', endTime, endTime, null);
      document.removeEventListener('mouseup', mouseupHandler);
      document.removeEventListener('mousemove', mousemoveHandler);
    };

    document.addEventListener('mouseup', mouseupHandler);
    const mousemoveHandler = (e: Event) => {
      e.preventDefault();
      const mouseEvent = e as MouseEvent;
      const currTime = Math.min(
        pixelsToSeconds(
          Math.max(mouseEvent.clientX - (tracksElementX - tracksElement.scrollLeft), 0),
          renderPlaylist.samplesPerPixel,
          renderPlaylist.sampleRate
        ),
        renderPlaylist.duration
      );

      renderPlaylist.ee.emit('select', currTime, currTime, null);
    };

    document.addEventListener('mousemove', mousemoveHandler);
  };
  tracksElement?.addEventListener('mousedown', mousedownHandler);
  return mousedownHandler;
};

const addComment = (renderPlaylist: any, currentTime: number, createCommentDialog: any) => {
  const tracksElement = document.getElementsByClassName('playlist-tracks')[0];

  if (!tracksElement) return;

  const mousedownHandler = async (e: Event) => {
    const mouseEvent = e as MouseEvent;
    const tracksElementX = tracksElement.getBoundingClientRect()?.left || 0;
    const tracksElementY = tracksElement.getBoundingClientRect()?.top || 0;

    mouseEvent.preventDefault();

    const onClick = (e: Event) => {
      e.preventDefault();

      renderPlaylist.ee.emit('select', currentTime, currentTime, null);

      const mouseEvent = e as MouseEvent;

      const duration = Math.min(
        pixelsToSeconds(
          Math.max(mouseEvent.clientX - (tracksElementX - tracksElement.scrollLeft), 0),
          renderPlaylist.samplesPerPixel,
          renderPlaylist.sampleRate
        ),
        renderPlaylist.duration
      );

      const stemIndex = Math.floor((mouseEvent.clientY - tracksElementY) / 61);

      !!createCommentDialog && createCommentDialog(stemIndex, duration);

      document.removeEventListener('click', onClick);
    };

    document.addEventListener('click', onClick);
  };

  tracksElement.addEventListener('mousedown', mousedownHandler);

  return mousedownHandler;
};

export function Playlist({ stems, onStopPlaying, versionId, soloMutedStems, colors, createCommentDialog }: PlaylistProps) {
  const theme = useTheme();
  let isSafari = useMemo(() => /^((?!chrome|android).)*safari/i.test(navigator.userAgent), []);
  const { songParentId, isOriginalStems, isCompressedExists, isCommentMode, comments, zoomLevel, stems: storeStems } = useSongSelector();
  const { songId: urlSongId } = useParams();
  const shouldRender = useMemo(() => songParentId === urlSongId, [songParentId, urlSongId]);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileLandscape = useMediaQuery('(max-height: 500px) and (orientation: landscape)');
  const soloMutedStemsRef = useRef(soloMutedStems);

  // Update the ref whenever propValue changes
  useEffect(() => {
    soloMutedStemsRef.current = soloMutedStems;
  }, [soloMutedStems]);
  const playlistState = usePlaylistStore(state => state.playListStates.find(state => state.versionId === versionId));
  const playlistStates = usePlaylistStore(state => state.playListStates);
  const init = usePlaylistStore(state => state.initPlaylist);
  const incrLoadedStemsCount = usePlaylistStore(state => state.incrLoadedStemsCount);
  const handleIsStemsRendered = usePlaylistStore(state => state.handleIsStemsRendered);
  const updateFileMetadata = usePlaylistStore(state => state.setFileMetadata);
  const setSoloMutedStems = usePlaylistStore(state => state.setSoloMutedStems);
  const dragHandler = useRef<((e: Event) => Promise<void>) | undefined>();
  const addCommentHandler = useRef<((e: Event) => Promise<void>) | undefined>();
  const [currentTime, setCurrentTime] = useState(0);
  const [playlistDuration, setPlayListDuration] = useState(0);
  const playListRef = useRef<any>();

  const handleFinish = useCallback(() => {
    setCurrentTime(0);
    onStopPlaying();
  }, [onStopPlaying]);

  const handleTimeUpdate = useCallback((time: number) => {
    setCurrentTime(time);
  }, []);

  useEffect(() => {
    if (playListRef.current) {
      if (isCommentMode) {
        // @ts-ignore
        document.getElementsByClassName('playlist-tracks')[0]?.removeEventListener('mousedown', dragHandler.current);

        dragHandler.current = undefined;
        addCommentHandler.current = addComment(playListRef.current, currentTime, createCommentDialog);
      } else {
        // @ts-ignore
        document.getElementsByClassName('playlist-tracks')[0]?.removeEventListener('mousedown', addCommentHandler.current);

        dragHandler.current = handlePlayHeadDrag(playListRef.current);
        addCommentHandler.current = undefined;
      }
    }
  }, [playListRef.current, isCommentMode]);

  const handleBlurAllInputs = () => {
    const inputs = document.querySelectorAll('input, textarea');
    inputs.forEach(input => {
      if (input instanceof HTMLInputElement || input instanceof HTMLTextAreaElement) {
        input.blur();
      }
    });
  };

  const destroy = () => {
    setSoloMutedStems(soloMutedStemsRef.current, versionId);
    playlistState?.eventEmitter.emit('pause');
    playlistState?.eventEmitter.removeListener('finished', handleFinish);
    playlistState?.eventEmitter.removeListener('timeupdate', handleTimeUpdate);
    if (dragHandler.current) {
      document.getElementsByClassName('playlist-tracks')[0]?.removeEventListener('mousedown', dragHandler.current);
    }

    if (addCommentHandler.current) {
      document.getElementsByClassName('playlist-tracks')[0]?.removeEventListener('mousedown', addCommentHandler.current);
    }
    let playlistNode = document.getElementsByClassName('playlist');

    if (playlistNode) {
      playlistNode?.item(0)?.parentElement?.removeChild(playlistNode?.item(0)!);
    }
  };
  const isStemsExit = useMemo(() => {
    return !!stems?.length;
  }, [stems]);

  useEffect(() => {
    if (playlistState?.playlist) {
      playListRef.current = playlistState.playlist;
      document.getElementById('waveform-container')?.appendChild(playlistState.playlist.rootNode);
      setCurrentTime(playlistState?.playlist?.pausedAt || 0);
      playlistState?.playlist.ee.on('finished', handleFinish);
      playlistState?.playlist.ee.on('timeupdate', handleTimeUpdate);
      return;
    }

    if (!isStemsExit || !shouldRender) {
      return;
    }

    const multiTrackStems = stems.map(stem => ({
      src: stem.src,
      name: stem.name,
      usePolyfillReader: !isSafari && stem.name.endsWith('.aiff') && (!isCompressedExists || isOriginalStems)
    }));

    const waveFormPlayListConfig = {
      samplesPerPixel: 16384,
      mono: true,
      waveHeight: isMobile || isMobileLandscape ? 116 : 61,
      container: document.getElementById('waveform-container'),
      timescale: true,
      state: 'cursor',
      controls: {
        show: false,
        width: 0
      },
      colors: {
        waveOutlineColor: colors?.waveOutlineColor || '#151515',
        timeColor: 'white',
        fadeColor: 'blue'
      },
      isAutomaticScroll: true,
      barWidth: isMobile ? 2 : 1,
      barGap: isMobile ? 2 : 1,
      zoomLevels: isMobile ? [8192, 10280, 12384, 16384] : [64, 96, 128, 256, 512, 1024, 2048, 4096, 6048, 8192, 10280, 12384, 16384]
    };

    const renderPlaylist: any = WaveformPlaylist(waveFormPlayListConfig, playlistState?.eventEmitter);

    // playlistState?.eventEmitter.on('audiofileloaded', async (e, r) => {
    //   const ans = await detectFileTypeAndReadMetadata(e);
    //   ans?.metadata && updateFileMetadata(ans.metadata, versionId);
    // });

    renderPlaylist
      .load(multiTrackStems, () => incrLoadedStemsCount(versionId))
      .then(async () => {
        init(renderPlaylist, versionId);

        setPlayListDuration(renderPlaylist.duration);
        renderPlaylist.ee.on('finished', handleFinish);
        renderPlaylist.ee.on('timeupdate', handleTimeUpdate);
      });

    playListRef.current = renderPlaylist;

    return () => {
      destroy();
    };
  }, [versionId, isStemsExit, shouldRender]);

  useEffect(() => {
    if (playListRef.current) {
      // @ts-ignore
      setPlayListDuration(playListRef.current.duration);
    }
    if (!stems.length) {
      destroy();
    }
  }, [stems]);

  return (
    <>
      <StemsPlayCustomTime currentTime={formatTime(Math.ceil(currentTime))} totalTime={formatTime(Math.ceil(playlistDuration))} />
      <Box sx={{ overflowX: 'none' }} position="relative">
        {playListRef?.current &&
          comments.map(c => (
            <Box
              key={c.id}
              position={'absolute'}
              zIndex={100}
              top={`${storeStems.findIndex(s => s.id === c.stemId) * 61 + 57}px`}
              left={`${secondsToPixels(
                Math.ceil(c.currentTime),
                playListRef?.current?.zoomLevels[playListRef?.current?.zoomLevels?.length - zoomLevel - 1],
                playListRef?.current?.sampleRate
              )}px`}
            >
              <CommentAvatar comment={c} />
            </Box>
          ))}
        <Box flexGrow={1000} id="waveform-container" onClick={handleBlurAllInputs}></Box>
      </Box>
    </>
  );
}
