import { Box, Button, Tooltip } from '@mui/material';
import React from 'react';
import { ReactComponent as CommentIcon } from '../../assets/icons/commentIcon.svg';

export type SongMetadataProps = {
  isHistoryCommentsOpened: boolean;
  toggleHistoryComments: () => void;
};

export function CommentsHistoryButton({ isHistoryCommentsOpened, toggleHistoryComments }: SongMetadataProps) {
  return (
    <Box>
      <Tooltip title={'Expand Comment history'}>
        <Button
          sx={{
            width: '66px',
            height: '28px',
            weight: '400',
            lineHeight: '18px',
            ...(!isHistoryCommentsOpened && {
              opacity: 0.5,
              alignItems: 'end'
            })
          }}
          onClick={toggleHistoryComments}
          disableElevation={true}
          size="small"
          variant="contained"
          color={'secondary'}
          startIcon={<CommentIcon />}
        >
          {isHistoryCommentsOpened ? (
            <img src="/assets/opened-eye.svg" alt="closed eye" />
          ) : (
            <img src="/assets/closed-eye.svg" alt="closed eye" />
          )}
        </Button>
      </Tooltip>
    </Box>
  );
}
