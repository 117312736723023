import { Box, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { ChangeEvent, useEffect, useState } from 'react';
import TimeScale from '../../components/multitrack/Timescale';
import { useSongSelector } from '../../store/selectors/song.selector';

export type SongBPMProps = {
  canEdit: boolean;
  playlist: any;
  playlistStateBpm: string | undefined;
  showFileMetadata: boolean;
};

export function SongBPM({ canEdit, playlist, playlistStateBpm, showFileMetadata }: SongBPMProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { editBPM, bpm: DBbpm } = useSongSelector();
  const [inputBpm, setInputBpm] = useState(0);
  const [oldBpm, setOldBpm] = useState(0);
  const bpm = showFileMetadata ? playlistStateBpm : DBbpm;
  useEffect(() => {
    setInputBpm(Number(bpm));
    setOldBpm(Number(bpm));
  }, [bpm]);

  const handleBpmChange = async (event: ChangeEvent<{ value: string }>) => {
    const bpmNum = Number(event.target.value);

    if (!event.target.value.match(/[^0-9]/) && bpmNum >= 0) {
      setInputBpm(bpmNum);

      if (playlist) {
        playlist.renderTimeScale = function () {
          const controlWidth = this.controls.show ? this.controls.width : 0;
          const timeScale = new TimeScale(
            this.duration,
            this.scrollLeft,
            this.samplesPerPixel,
            this.sampleRate,
            this.colors,
            controlWidth,
            bpmNum
          );
          return timeScale.render();
        };
        playlist.drawRequest();
      }
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '12px'
      }}
    >
      <Typography
        sx={{
          fontSize: '14px',
          lineHeight: '17px',
          color: '#ffff'
        }}
      >
        BPM
      </Typography>
      {
        <TextField
          onBlur={e => {
            if (oldBpm !== inputBpm) {
              setOldBpm(inputBpm);
              editBPM(Number(e.target.value));
              enqueueSnackbar('BPM changed successfully', { variant: 'success' });
            }
          }}
          onChange={handleBpmChange}
          disabled={!canEdit}
          size="small"
          value={inputBpm}
          sx={{
            width: `calc(45px + ${inputBpm.toString().length > 3 ? 7 * inputBpm.toString().length : 0}px)`,
            maxHeight: '27px',
            fontSize: '14px',
            '& .MuiOutlinedInput-root': {
              borderRadius: '8px'
            },
            '& fieldset': { border: 'none', borderRadius: '4px' },
            '& .MuiInputLabel-root': { fontSize: '14px' },
            '& .MuiInputBase-input': { fontSize: '14px', padding: '4px 10px', fontWeight: '400', lineHeight: '17px' },
            '& .MuiInputBase-root': { backgroundColor: '#2B2B2B', maxHeight: '27px' }
          }}
          inputProps={{
            min: '0',
            inputMode: 'numeric'
          }}
        />
      }
    </Box>
  );
}
