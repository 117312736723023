import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { Avatar, Button, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Comment } from '../../@types/comment';
import commentService from '../../services/commentService';
import { useSongSelector } from '../../store/selectors/song.selector';
import { formatDateMDTime } from '../../utils/date';
import { formatTime } from '../../utils/helpers';

type CommentHistorySidebarItemProps = {
  comment: Comment;
};

export function CommentHistorySidebarItem({ comment }: CommentHistorySidebarItemProps) {
  const commentRef = useRef<any>();
  const [showInput, setShowInput] = useState(false);
  const { getComments } = useSongSelector();
  const [newMessage, setNewMessage] = useState('');

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (commentRef.current && !commentRef.current.contains(event.target)) {
        setShowInput(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleSaveComment = async () => {
    if (!newMessage) return;

    await commentService.updateComment(comment.id, newMessage);
    setNewMessage('');
    setShowInput(false);

    await getComments();
  };

  return (
    <Stack
      ref={commentRef}
      onClick={() => setShowInput(true)}
      sx={{ background: 'rgba(31, 31, 31, 1)' }}
      pt={1.25}
      px={1.25}
      pb={2}
      borderRadius={4}
      position="relative"
      gap={1}
    >
      <IconButton
        size="small"
        sx={{ position: 'absolute', right: 0, top: 0 }}
        onClick={async () => {
          await commentService.deleteComment(comment.id);

          await getComments();
        }}
      >
        <CheckCircleOutlineOutlinedIcon opacity={0.5} />{' '}
      </IconButton>
      {comment?.commentMessages?.map((message, index) => (
        <Grid container columnSpacing={1}>
          <Grid item xs="auto">
            <Avatar
              sx={{
                bgcolor: '#008EF3',
                color: '#FFFFFF',
                width: '23px',
                height: '23px',
                fontFamily: 'DM Sans, sans-serif',
                fontSize: '14px'
              }}
            >
              {message.createdBy?.name?.charAt(0).toUpperCase() ?? message.createdBy?.email?.charAt(0).toUpperCase()}
            </Avatar>
          </Grid>
          <Grid item xs>
            <Typography sx={{ fontWeight: '500', fontSize: '14px', lineHeight: '24px', color: '#FFFFFF', marginBottom: '3px' }}>
              {message.createdBy.name ?? message.createdBy.email}{' '}
              {index === 0 && (
                <>
                  <span style={{ color: '#A7A7A7' }}>at</span>{' '}
                  <span style={{ color: '#008EF3', fontWeight: '600', marginRight: '8px' }}>
                    {formatTime(Math.ceil(comment.currentTime))}
                  </span>
                </>
              )}
              <span style={{ color: '#A7A7A7' }}>{formatDateMDTime(message.createdAt, true)}</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '24px',
                color: '#FFFFFF',
                marginLeft: '32px'
              }}
            >
              {message.commentText}
            </Typography>
          </Grid>
        </Grid>
      ))}
      {showInput && (
        <>
          <Stack
            sx={{
              width: '100%',
              alignItems: 'center',
              backgroundColor: 'rgba(75, 75, 75, 0.4)',
              border: '1px solid #ABABAB',
              borderRadius: '36px',
              paddingInline: '18px'
            }}
          >
            <TextField
              value={newMessage}
              sx={{
                padding: 0,
                '& .MuiInputBase-root::before, & .MuiInputBase-root::after': {
                  display: 'none'
                }
              }}
              margin="dense"
              placeholder={comment?.id ? 'Reply' : 'Comment'}
              size="small"
              autoComplete="off"
              variant="standard"
              fullWidth
              onChange={e => setNewMessage(e.target.value)}
            />
          </Stack>
          <Stack
            direction={'row'}
            sx={{
              justifyContent: 'flex-end',
              gap: '8px'
            }}
          >
            <Button
              onClick={() => handleSaveComment()}
              sx={{
                width: '100px',
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '18.23px',
                letterSpacing: '-0.4%',
                paddingBlock: '11px',
                paddingInline: '18px',
                borderRadius: '36px',
                backgroundColor: '#008EF3'
              }}
              variant="contained"
            >
              Reply
            </Button>
          </Stack>
        </>
      )}
    </Stack>
  );
}
