import { Avatar, Stack, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material';
import { useState } from 'react';
import { Comment } from '../../@types/comment';
import { formatTime } from '../../utils/helpers';
import { formatDateMDTime } from '../../utils/date';
import styled from '@emotion/styled';
import CommentDialog from './CommentDialog';

type CommentOnHoverProps = {
  comment: Comment;
};

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    background: '#1F1F1F',
    borderBottomRightRadius: '16px',
    borderTopRightRadius: '16px',
    borderTopLeftRadius: '16px',
    padding: '6px'
  }
});

export default function CommentAvatar({ comment }: CommentOnHoverProps) {
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  return (
    <>
      {isDialogOpened && <CommentDialog onClose={() => setIsDialogOpened(false)} open={isDialogOpened} comment={comment} />}
      <CustomTooltip
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setIsDialogOpened(true);
        }}
        title={
          <Stack>
            {comment?.commentMessages?.map((message, index) => (
              <>
                <Stack direction={'row'} gap={1}>
                  <Avatar
                    sx={{
                      bgcolor: '#008EF3',
                      color: '#FFFFFF',
                      width: '23px',
                      height: '23px',
                      fontFamily: 'DM Sans, sans-serif',
                      fontSize: '14px'
                    }}
                  >
                    {message.createdBy?.name?.charAt(0).toUpperCase() ?? message.createdBy?.email?.charAt(0).toUpperCase()}
                  </Avatar>
                  <Typography sx={{ fontWeight: '500', fontSize: '14px', lineHeight: '24px', color: '#FFFFFF', marginBottom: '3px' }}>
                    {message.createdBy.name ?? message.createdBy.email}{' '}
                    {index === 0 && (
                      <>
                        <span style={{ color: '#A7A7A7' }}>at</span>{' '}
                        <span style={{ color: '#008EF3', fontWeight: '600', marginRight: '20px' }}>
                          {formatTime(Math.ceil(comment.currentTime))}
                        </span>
                      </>
                    )}
                    <span style={{ color: '#A7A7A7' }}>{formatDateMDTime(message.createdAt, true)}</span>
                  </Typography>
                </Stack>

                <Typography
                  sx={{
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '24px',
                    color: '#FFFFFF',
                    marginBottom: '14px',
                    marginLeft: '32px'
                  }}
                >
                  {message.commentText}
                </Typography>
              </>
            ))}
          </Stack>
        }
      >
        <Stack
          sx={{
            background: 'rgb(31, 31, 31)',
            borderBottomRightRadius: '16px',
            borderTopRightRadius: '16px',
            borderTopLeftRadius: '16px',
            padding: '6px',
            boxShadow: '0px 2px 5px 2px rgba(0, 0, 0, 0.7)',
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              borderRadius: '16px',
              padding: '0.5px',
              background: 'linear-gradient(230deg, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%)',
              WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
              maskComposite: 'exclude',
              zIndex: 0
            },
            zIndex: 1
          }}
        >
          <Avatar
            sx={{
              bgcolor: '#008EF3',
              color: '#FFFFFF',
              width: '23px',
              height: '23px',
              fontFamily: 'DM Sans, sans-serif',
              fontSize: '14px'
            }}
          >
            {comment?.commentMessages[0]?.createdBy?.name?.charAt(0).toUpperCase() ??
              comment?.commentMessages[0]?.createdBy?.email?.charAt(0).toUpperCase()}
          </Avatar>
        </Stack>
      </CustomTooltip>
    </>
  );
}
