import { Box, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
// @ts-ignore
import { LoudnessMeter } from '@domchristie/needles';
import { SoloMutedStems } from '../@types/songs';
import CloseIcon from '@mui/icons-material/Close';

async function combineBuffers(sampleRate: number, buffers: AudioBuffer[]) {
  const maxDuration = Math.max(...buffers.map(buffer => buffer.duration));

  const offlineContext = new OfflineAudioContext(buffers[0].numberOfChannels, maxDuration * sampleRate, sampleRate);

  buffers.forEach(buffer => {
    const source = offlineContext.createBufferSource();
    source.buffer = buffer;
    source.connect(offlineContext.destination);
    source.start(0);
  });

  const combinedBuffer = await offlineContext.startRendering();

  return combinedBuffer;
}
export function CombinedLufsItem({ playlist, soloMutedStems, handleCloseLufs }: { playlist: any; soloMutedStems: Map<string, SoloMutedStems>, handleCloseLufs: () => void }) {
  const [integratesLufs, setIntegratesLufs] = useState(undefined);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobileLandscape = useMediaQuery('(max-height: 500px) and (orientation: landscape)');
  useEffect(() => {
    if (!soloMutedStems || !playlist) {
      return undefined;
    }
    let source = playlist.ac.createBufferSource();
    var loudnessMeter = new LoudnessMeter({
      source,
      workerUri: new URL('@domchristie/needles/dist/needles-worker.js', import.meta.url),
      modes: ['integrated'],
      offline: true
    });
    function listener(event: any) {
      setIntegratesLufs(event.data.value);
    }
    loudnessMeter.on('dataavailable', listener);
    loudnessMeter.start();
    const processCombinedBuffers = async () => {
      const buffers: any[] = [];
      playlist.tracks.forEach((track: any) => {
        if (playlist.shouldTrackPlay(track)) {
          buffers.push(track.buffer);
        }
      });
      const audioBuffer = await combineBuffers(playlist.sampleRate, buffers);
      loudnessMeter.input(audioBuffer);
    };
    processCombinedBuffers();

    return () => {
      loudnessMeter.off('dataavailable', listener);
      loudnessMeter.stop();
    };
  }, [soloMutedStems]);

  return (
    <Stack sx={{ top: '0', position: 'sticky' }}>
      <Stack
        sx={{
          background: isMobile ? 'black' : '#151515',
          maxHeight: isMobile || isMobileLandscape ? '73px' : '61px',
          minHeight: isMobile || isMobileLandscape ? '73px' : '61px',
          borderRight: '1px solid #494949',
          color: 'white'
        }}
        direction="column"
        py={1}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Typography sx={{ fontWeight: '400', fontSize: '10px', lineHeight: '13px', opacity: '0.5' }}>Integrated </Typography>
        <Typography sx={{ fontWeight: '700', fontSize: '12px', lineHeight: '15.62px', paddingTop: '3px' }}>
          {integratesLufs && Math.round(integratesLufs * 100) / 100}
        </Typography>

        <IconButton
          className="icon-hover"
          size="small"
          onClick={handleCloseLufs}
          sx={{
            minWidth: 'unset',
            width: '24px',
            height: '24px',
            position: 'absolute',
            right: 2,
            top: 2,
            opacity: 0, 
            zIndex: 2,
            transition: 'opacity 0.3s ease',
            '& .MuiSvgIcon-root': {
              fontSize: '14px',
              opacity: 0.6,
            },
            '&:hover': {
              opacity: 1,
            },
          }}
        >
          <CloseIcon/>
        </IconButton>
      </Stack>
      <Box height="1px" width="100%" sx={{ backgroundColor: '#494949' }} />
    </Stack>
  );
}


