import DescriptionIcon from '@mui/icons-material/Description';
import { Box, Icon, Stack, Tooltip, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { FileType } from '../@types/files';
import type { SearchContent } from '../@types/search';
export type SearchContentItemProps = {
  content: SearchContent;
};

export default function SearchContentItem({ content }: SearchContentItemProps) {
  const screenWidth = window.innerWidth;
  const { songId } = useParams();
  const navigate = useNavigate();
  return (
    <Stack
      sx={{ cursor: 'pointer' }}
      onClick={() =>
        navigate(
          `/dashboard/${content.folderId}/${content.type === 'stem' ? content.songId : content.id}${
            content.fileType === FileType.Other ? '/file' : ''
          }`
        )
      }
      direction="row"
      spacing={2}
      alignItems="center"
    >
      <Box
        minWidth="48px"
        width="48px"
        height="48px"
        position="relative"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background:
            content.id.includes(songId!) || content?.songId?.includes(songId!)
              ? 'linear-gradient(266deg, #008EF3 8.39%, rgba(22, 225, 245, 0.85) 88.47%)'
              : '#4B4B4B66',
          borderRadius: 1
        }}
      >
        {content.type === 'stem' ? (
          <Icon>
            <img src={'/assets/wave.svg'} height={24} width={24} alt="song" />
          </Icon>
        ) : content.fileType === FileType.Audio ? (
          <Icon>
            <img src={'/assets/songIconUploadPage.svg'} height={16} width={16} alt="song" />
          </Icon>
        ) : (
          <DescriptionIcon fontSize="medium" />
        )}
      </Box>
      <Box>
        <Tooltip title={content?.name ?? ''}>
          <Typography
            sx={{
              '&.MuiTypography-root': {
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                maxWidth: `${Math.round((screenWidth / 800) * 100)}px`
              }
            }}
          >
            {content?.name ?? ''}
          </Typography>
        </Tooltip>
      </Box>
    </Stack>
  );
}
